import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  IconButton,
  DialogActions,
  Button,
  CircularProgress,
  Box,
  TextField,
  InputAdornment,
  DialogContentText
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import TextService from '../api/textService';

function TextListDialog({ open, onClose, onSelect }) {
  const [texts, setTexts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [textToDelete, setTextToDelete] = useState(null);

  const loadTexts = async (search = '') => {
    try {
      const textList = await TextService.getTexts(search);
      setTexts(textList);
    } catch (error) {
      console.error('Error loading texts:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (text, event) => {
    event.stopPropagation();
    setTextToDelete(text);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!textToDelete) return;
    
    try {
      await TextService.deleteText(textToDelete.id);
      await loadTexts(searchTerm);
    } catch (error) {
      console.error('Error deleting text:', error);
    } finally {
      setDeleteConfirmOpen(false);
      setTextToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setTextToDelete(null);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const timeoutId = setTimeout(() => {
      setLoading(true);
      loadTexts(value);
    }, 300);

    setSearchTimeout(timeoutId);
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      loadTexts(searchTerm);
    }
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [open]);

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          sx: {
            maxHeight: '80vh'
          }
        }}
      >
        <DialogTitle>Gespeicherte Texte</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Suchen..."
            value={searchTerm}
            onChange={handleSearch}
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : texts.length === 0 ? (
            <Box sx={{ p: 2, textAlign: 'center' }}>
              {searchTerm ? 'Keine Texte gefunden' : 'Keine gespeicherten Texte vorhanden'}
            </Box>
          ) : (
            <List>
              {texts.map((text) => (
                <ListItem
                  key={text.id}
                  secondaryAction={
                    <IconButton 
                      edge="end" 
                      onClick={(e) => handleDeleteClick(text, e)}
                      title="Text löschen"
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                  button
                  onClick={() => onSelect(text)}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    },
                    borderRadius: 1,
                    mb: 1
                  }}
                >
                  <ListItemText
                    primary={text.project_name || 'Unbenanntes Projekt'}
                    secondary={new Date(text.created_at).toLocaleString()}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>Text löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchten Sie wirklich den Text "{textToDelete?.project_name || 'Unbenanntes Projekt'}" löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TextListDialog;
