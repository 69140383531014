import React, { useState } from 'react';
import { Container } from '@mui/material';
import { convertFromRaw } from 'draft-js';
import TextService from '../api/textService';
import TextListDialog from './TextListDialog';
import MenuHeader from './menu/MenuHeader';
import TextEditorSection from './menu/TextEditorSection';
import SettingsControls from './menu/SettingsControls';
import ActionButtons from './menu/ActionButtons';
import FlipControls from './menu/FlipControls';
import LegalLinks from './menu/LegalLinks';
import { 
  SuccessDialog, 
  OverwriteDialog, 
  TextEditorDialog, 
  HelpDialog,
  LegalDialog 
} from './menu/dialogs';
import {
  IMPRESSUM_HTML,
  DATENSCHUTZ_HTML
} from '../content/legal';
import AppSettingsDialog from './menu/dialogs/AppSettingsDialog';

function Menu({
  projectName, setProjectName, text, setText, fontSize, setFontSize,
  speed, setSpeed, textColor, setTextColor, startDelay, setStartDelay,
  handleStart, handleStop, handleReset: parentHandleReset, toggleMenu, onLogout, formattedText,
  customCommand, setCustomCommand, isFlippedX, setIsFlippedX, isFlippedY, setIsFlippedY,
  isRemoteConnected, containerWidth, setContainerWidth, onSettingsClick, backgroundColor, onBackgroundColorChange,
  scrollSpeechEnabled, onScrollSpeechEnabledChange, scrollSpeechCommand, onScrollSpeechCommandChange,
  autoHideControls, onAutoHideControlsChange, speechError, transcript, onPauseResume
}) {
  const [textListOpen, setTextListOpen] = useState(false);
  const [textEditorOpen, setTextEditorOpen] = useState(false);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [editingText, setEditingText] = useState(text);
  const [currentFormattedText, setCurrentFormattedText] = useState(formattedText);
  const [overwriteDialogOpen, setOverwriteDialogOpen] = useState(false);
  const [existingText, setExistingText] = useState(null);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [legalDialogOpen, setLegalDialogOpen] = useState(false);
  const [legalContent, setLegalContent] = useState({ title: '', content: '' });
  const [appSettingsOpen, setAppSettingsOpen] = useState(false);

  const generateUniqueProjectName = (baseName = 'Neues_Projekt') => {
    const now = new Date();
    const timestamp = now.toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }).replace(/[.,]/g, '').replace(/:/g, '').replace(/ /g, '_');
    return `${baseName}_${timestamp}`;
  };

  const handleNew = () => {
    const newName = generateUniqueProjectName();
    setProjectName(newName);
    setText('', '');
    setCurrentFormattedText('');
    setEditingText('');
    setFontSize(24);
    setSpeed(50);
    setStartDelay(3);
    setTextColor('#FFFFFF');
    setCustomCommand('achtung');
    setIsFlippedX(false);
    setIsFlippedY(false);
    setContainerWidth(95);
    onScrollSpeechEnabledChange(false);
    onScrollSpeechCommandChange('pause');
    onAutoHideControlsChange(false);
    setSuccessMessage('Neues Projekt wurde erstellt.');
    setSuccessDialogOpen(true);
  };

  const saveText = async (overwrite = false, newName = null) => {
    try {
      const textData = {
        projectName: newName || projectName,
        text: currentFormattedText || text,
        settings: {
          fontSize,
          speed,
          textColor,
          startDelay,
          customCommand,
          isFlippedX,
          isFlippedY,
          containerWidth,
          scrollSpeechEnabled,
          scrollSpeechCommand,
          autoHideControls
        }
      };

      const result = await TextService.createText(textData, overwrite);
      
      if (result && result.success) {
        if (newName) {
          setProjectName(newName);
        }
        setSuccessMessage(`Text "${newName || projectName}" wurde erfolgreich gespeichert!`);
        setSuccessDialogOpen(true);
        setOverwriteDialogOpen(false);
      } else {
        throw new Error('Speichern fehlgeschlagen');
      }
    } catch (error) {
      console.error('Error saving text:', error);
      setSuccessMessage('Fehler beim Speichern des Textes.');
      setSuccessDialogOpen(true);
    }
  };

  const handleSave = async () => {
    try {
      const existing = await TextService.checkExists(projectName);
      if (existing) {
        setExistingText(existing);
        setOverwriteDialogOpen(true);
        return;
      }

      await saveText(false);
    } catch (error) {
      console.error('Error saving text:', error);
      setSuccessMessage('Fehler beim Speichern des Textes.');
      setSuccessDialogOpen(true);
    }
  };

  const handleCreateNew = () => {
    const newName = generateUniqueProjectName(projectName);
    saveText(false, newName);
  };

  const handleTextSelect = (selectedText) => {
    try {
      const textData = JSON.parse(selectedText.text_content);
      setProjectName(textData.projectName || '');
      setText(textData.text || '', textData.text);
      setCurrentFormattedText(textData.text);
      if (textData.settings) {
        setFontSize(textData.settings.fontSize);
        setSpeed(textData.settings.speed);
        setTextColor(textData.settings.textColor);
        setStartDelay(textData.settings.startDelay);
        if (textData.settings.customCommand) {
          setCustomCommand(textData.settings.customCommand);
        }
        if (typeof textData.settings.isFlippedX === 'boolean') {
          setIsFlippedX(textData.settings.isFlippedX);
        }
        if (typeof textData.settings.isFlippedY === 'boolean') {
          setIsFlippedY(textData.settings.isFlippedY);
        }
        if (typeof textData.settings.containerWidth === 'number') {
          setContainerWidth(textData.settings.containerWidth);
        }
        if (typeof textData.settings.scrollSpeechEnabled === 'boolean') {
          onScrollSpeechEnabledChange(textData.settings.scrollSpeechEnabled);
        }
        if (textData.settings.scrollSpeechCommand) {
          onScrollSpeechCommandChange(textData.settings.scrollSpeechCommand);
        }
        if (typeof textData.settings.autoHideControls === 'boolean') {
          onAutoHideControlsChange(textData.settings.autoHideControls);
        }
      }
      setTextListOpen(false);
    } catch (error) {
      console.error('Error parsing text data:', error);
      setSuccessMessage('Fehler beim Laden des Textes.');
      setSuccessDialogOpen(true);
    }
  };

  const handleEditorChange = (plainText, formattedState) => {
    setEditingText(plainText);
    setCurrentFormattedText(formattedState);
  };

  const handleReset = () => {
    setFontSize(24);
    setSpeed(50);
    setStartDelay(3);
    setTextColor('#FFFFFF');
    setCustomCommand('achtung');
    setIsFlippedX(false);
    setIsFlippedY(false);
    setContainerWidth(95);
    onScrollSpeechEnabledChange(false);
    onScrollSpeechCommandChange('pause');
    onAutoHideControlsChange(false);
    
    if (parentHandleReset) {
      parentHandleReset();
    }
  };

  const getPlainText = (rawContent) => {
    try {
      if (typeof rawContent === 'string' && rawContent.startsWith('{')) {
        const contentState = convertFromRaw(JSON.parse(rawContent));
        return contentState.getPlainText();
      }
      return rawContent;
    } catch (e) {
      return rawContent;
    }
  };

  const handleImpressumClick = () => {
    setLegalContent({
      title: 'Impressum',
      content: IMPRESSUM_HTML
    });
    setLegalDialogOpen(true);
  };

  const handleDatenschutzClick = () => {
    setLegalContent({
      title: 'Datenschutzerklärung',
      content: DATENSCHUTZ_HTML
    });
    setLegalDialogOpen(true);
  };

  return (
    <Container>
      <MenuHeader 
        onHelpClick={() => setHelpDialogOpen(true)}
        onLogout={onLogout}
        onClose={toggleMenu}
        onNew={handleNew}
        onSave={handleSave}
        onLoad={() => setTextListOpen(true)}
        onSettingsClick={() => setAppSettingsOpen(true)}
      />

      <TextEditorSection
        projectName={projectName}
        onProjectNameChange={setProjectName}
        onEditClick={() => setTextEditorOpen(true)}
        previewText={getPlainText(currentFormattedText || text)}
      />

      <SettingsControls
        fontSize={fontSize}
        onFontSizeChange={setFontSize}
        speed={speed}
        onSpeedChange={setSpeed}
        startDelay={startDelay}
        onStartDelayChange={setStartDelay}
        containerWidth={containerWidth}
        onContainerWidthChange={setContainerWidth}
        bypassLock={isRemoteConnected}
      />

      <ActionButtons
        onStart={handleStart}
        onStop={handleStop}
        onReset={handleReset}
      />

      <FlipControls
        isFlippedX={isFlippedX}
        onFlipX={() => setIsFlippedX(!isFlippedX)}
        isFlippedY={isFlippedY}
        onFlipY={() => setIsFlippedY(!isFlippedY)}
      />

      <LegalLinks
        onImpressumClick={handleImpressumClick}
        onDatenschutzClick={handleDatenschutzClick}
      />

      <TextListDialog
        open={textListOpen}
        onClose={() => setTextListOpen(false)}
        onSelect={handleTextSelect}
      />

      <TextEditorDialog
        open={textEditorOpen}
        value={currentFormattedText || editingText}
        onChange={handleEditorChange}
        onClose={() => setTextEditorOpen(false)}
        onSave={() => {
          setText(editingText, currentFormattedText);
          setTextEditorOpen(false);
        }}
      />

      <OverwriteDialog
        open={overwriteDialogOpen}
        projectName={projectName}
        onCancel={() => {
          setOverwriteDialogOpen(false);
          setExistingText(null);
        }}
        onCreateNew={handleCreateNew}
        onOverwrite={() => saveText(true)}
      />

      <SuccessDialog
        open={successDialogOpen}
        message={successMessage}
        onClose={() => setSuccessDialogOpen(false)}
      />

      <HelpDialog
        open={helpDialogOpen}
        onClose={() => setHelpDialogOpen(false)}
      />

      <LegalDialog
        open={legalDialogOpen}
        onClose={() => setLegalDialogOpen(false)}
        title={legalContent.title}
        content={legalContent.content}
      />

      <AppSettingsDialog
        open={appSettingsOpen}
        onClose={() => setAppSettingsOpen(false)}
        backgroundColor={backgroundColor}
        onBackgroundColorChange={onBackgroundColorChange}
        scrollSpeechEnabled={scrollSpeechEnabled}
        onScrollSpeechEnabledChange={onScrollSpeechEnabledChange}
        scrollSpeechCommand={scrollSpeechCommand}
        onScrollSpeechCommandChange={onScrollSpeechCommandChange}
        autoHideControls={autoHideControls}
        onAutoHideControlsChange={onAutoHideControlsChange}
        speechError={speechError}
        transcript={transcript}
      />
    </Container>
  );
}

export default Menu;
