import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const LegalDialog = ({ open, onClose, title, content, isDarkMode }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      scroll="paper"
      PaperProps={{
        sx: {
          bgcolor: isDarkMode ? '#1e1e1e' : 'white',
          color: isDarkMode ? 'white' : 'inherit',
          '& .MuiDialogTitle-root': {
            bgcolor: isDarkMode ? '#1e1e1e' : 'white',
            color: isDarkMode ? 'white' : 'inherit',
          },
          '& .MuiDialogContent-root': {
            bgcolor: isDarkMode ? '#1e1e1e' : 'white',
            color: isDarkMode ? 'white' : 'inherit',
            '& a': {
              color: isDarkMode ? '#90caf9' : '#1976d2',
            },
          },
          '& .MuiDialogActions-root': {
            bgcolor: isDarkMode ? '#1e1e1e' : 'white',
          },
          '& .MuiIconButton-root': {
            color: isDarkMode ? 'white' : 'rgba(0, 0, 0, 0.54)',
          },
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {title}
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <div 
          dangerouslySetInnerHTML={{ __html: content }}
          style={{
            '& p': {
              color: isDarkMode ? 'white' : 'inherit',
            },
            '& h1, & h2, & h3, & h4, & h5, & h6': {
              color: isDarkMode ? 'white' : 'inherit',
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose} 
          variant="contained"
          sx={{
            bgcolor: isDarkMode ? '#333' : '#1976d2',
            color: 'white',
            '&:hover': {
              bgcolor: isDarkMode ? '#444' : '#1565c0',
            },
          }}
        >
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LegalDialog;
