import React from 'react';
import { 
  Box, 
  Button 
} from '@mui/material';

const ActionButtons = ({ onStart, onStop, onReset }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, my: 2 }}>
      <Button variant="contained" color="primary" onClick={onStart}>
        Start
      </Button>
      <Button variant="contained" color="secondary" onClick={onStop}>
        Stop
      </Button>
      <Button variant="contained" color="info" onClick={onReset}>
        Reset
      </Button>
    </Box>
  );
};

export default ActionButtons;
