import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Drawer, useMediaQuery, useTheme, ThemeProvider, createTheme, CircularProgress } from '@mui/material';
import Menu from './components/Menu';
import LoginForm from './components/LoginForm';
import Header from './components/Header';
import Controls from './components/Controls';
import TextDisplay from './components/TextDisplay';
import useTeleprompter from './hooks/useTeleprompter';
import useVoiceCommands from './hooks/useVoiceCommands';
import useScrollSpeechRecognition from './hooks/useScrollSpeechRecognition';
import { useRemoteControl } from './hooks/useRemoteControl';
import { generateProjectName } from './utils/fileOperations';
import auth from './components/auth';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#000000',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#ffffff',
    },
  },
});

function TeleprompterApp({ onLogout }) {
  const [text, setText] = useState('');
  const [formattedText, setFormattedText] = useState(null);
  const [fontSize, setFontSize] = useState(24);
  const [speed, setSpeed] = useState(50);
  const [containerWidth, setContainerWidth] = useState(95);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [adjustPosition, setAdjustPosition] = useState(0);
  const [textColor, setTextColor] = useState('#FFFFFF');
  const [startDelay, setStartDelay] = useState(3);
  const [projectName, setProjectName] = useState('');
  const [customCommand, setCustomCommand] = useState('achtung');
  const [isFlippedX, setIsFlippedX] = useState(false);
  const [isFlippedY, setIsFlippedY] = useState(false);
  const [scrollSpeechEnabled, setScrollSpeechEnabled] = useState(false);
  const [scrollSpeechCommand, setScrollSpeechCommand] = useState('achtung');
  const [autoHideControls, setAutoHideControls] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [recognitionInstance, setRecognitionInstance] = useState(null);
  const pauseButtonRef = useRef(null);
  const appContainerRef = useRef(null);
  const [backgroundColor, setBackgroundColor] = useState('#000000');

  const {
    isScrolling,
    isPaused,
    scrollPosition,
    countdown,
    textRef,
    containerRef,
    start,
    stop,
    togglePauseResume,
    setScrollPosition
  } = useTeleprompter(speed, isFlippedY, isFlippedX);

  const handleStart = useCallback(() => {
    setAdjustPosition(0);
    start(startDelay);
    setIsMenuOpen(false);
  }, [start, startDelay]);

  const handleStop = useCallback(() => {
    stop();
    setAdjustPosition(0);
  }, [stop]);

  const handleReset = useCallback(() => {
    handleStop();
  }, [handleStop]);

  const handlePauseCommand = useCallback(() => {
    if (pauseButtonRef.current) {
      console.log('Clicking pause button');
      pauseButtonRef.current.click();
    }
  }, []);

  const toggleFullscreen = useCallback(() => {
    try {
      const elem = appContainerRef.current;
      if (!elem) return;

      if (!document.fullscreenElement && !document.webkitFullscreenElement) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen();
        }
        setIsFullscreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        setIsFullscreen(false);
      }
    } catch (err) {
      console.error('Fullscreen error:', err);
    }
  }, []);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const handleAdjustPosition = useCallback((_, newValue) => {
    if (!textRef.current || !containerRef.current) return;

    // Konstante Schrittgröße in Pixeln
    const STEP_SIZE = 20; // Pixel pro Slider-Einheit
    
    // Berechne die Verschiebung basierend auf der Änderung des Slider-Werts
    const delta = newValue - adjustPosition;
    const pixelAdjustment = delta * STEP_SIZE;
    
    // Hole aktuelle Position
    const computedStyle = window.getComputedStyle(textRef.current);
    const matrix = new DOMMatrix(computedStyle.transform);
    const currentPos = matrix.m42;
    
    // Berechne neue Position relativ zur aktuellen Position
    const newPosition = currentPos + (isFlippedY ? pixelAdjustment : -pixelAdjustment);
    
    setAdjustPosition(newValue);
    setScrollPosition(newPosition);
  }, [adjustPosition, isFlippedY, textRef, containerRef, setScrollPosition]);

  const handleTextChange = useCallback((newText, formattedState) => {
    setText(newText);
    setFormattedText(formattedState);
  }, []);

  const handlePauseResume = useCallback(() => {
    if (isPaused) {
      setAdjustPosition(0);
    }
    togglePauseResume();
  }, [isPaused, togglePauseResume]);

  const handleRemoteCommand = useCallback((command) => {
    console.log('Received remote command:', command);
    
    if (command.startsWith('adjust_')) {
      const position = parseInt(command.split('_')[1], 10);
      handleAdjustPosition(null, position);
    } else {
      switch (command) {
        case 'start':
          handleStart();
          break;
        case 'stop':
          handleStop();
          break;
        case 'pause':
          handlePauseCommand();
          break;
        case 'reset':
          handleReset();
          break;
        case 'faster':
          setSpeed(prev => Math.min(prev + 5, 100));
          break;
        case 'slower':
          setSpeed(prev => Math.max(prev - 5, 10));
          break;
        case 'fullscreen':
          toggleFullscreen();
          break;
        default:
          console.log('Unknown command:', command);
      }
    }
  }, [handleStart, handleStop, handlePauseCommand, handleReset, handleAdjustPosition, toggleFullscreen]);

  const { isConnected: isRemoteConnected } = useRemoteControl(handleRemoteCommand);

  const voiceCommands = {
    [`${customCommand} start`]: handleStart,
    [`${customCommand} stop`]: handleStop,
    [`${customCommand} pause`]: handlePauseCommand,
    [`${customCommand} weiter`]: handlePauseCommand,
    [`${customCommand} reset`]: handleReset,
    [`${customCommand} menü`]: () => setIsMenuOpen(true),
    [`${customCommand} menü schließen`]: () => setIsMenuOpen(false),
    [`${customCommand} vollbild`]: toggleFullscreen
  };

  const {
    isListening,
    error: voiceError,
    lastCommand,
    startListening,
    stopListening
  } = useVoiceCommands(voiceCommands);

  const toggleVoiceControl = useCallback(() => {
    if (isListening) {
      stopListening(recognitionInstance);
      setRecognitionInstance(null);
    } else {
      const recognition = startListening();
      setRecognitionInstance(recognition);
    }
  }, [isListening, startListening, stopListening, recognitionInstance]);

  useEffect(() => {
    setProjectName(generateProjectName());
  }, []);

  // Initialize scroll speech recognition at app level
  const { error: speechError, transcript } = useScrollSpeechRecognition(
    scrollSpeechEnabled,
    scrollSpeechCommand,
    handlePauseCommand,
    handleStart,
    handleStop
  );

  return (
    <Box ref={appContainerRef} sx={{ 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column', 
      bgcolor: backgroundColor
    }}>
      <Header 
        toggleMenu={toggleMenu}
        toggleFullscreen={toggleFullscreen}
        isFullscreen={isFullscreen}
        isRemoteConnected={isRemoteConnected}
      />

      <Drawer 
        anchor="left" 
        open={isMenuOpen} 
        onClose={toggleMenu}
        PaperProps={{
          sx: {
            width: isSmallScreen ? '100%' : '400px',
            maxWidth: '100%',
            bgcolor: 'background.paper',
            color: 'white'
          },
        }}
      >
        <Menu
          projectName={projectName}
          setProjectName={setProjectName}
          text={text}
          setText={handleTextChange}
          fontSize={fontSize}
          setFontSize={setFontSize}
          speed={speed}
          setSpeed={setSpeed}
          containerWidth={containerWidth}
          setContainerWidth={setContainerWidth}
          textColor={textColor}
          setTextColor={setTextColor}
          startDelay={startDelay}
          setStartDelay={setStartDelay}
          handleStart={handleStart}
          handleStop={handleStop}
          handleReset={handleReset}
          toggleMenu={toggleMenu}
          onLogout={onLogout}
          formattedText={formattedText}
          customCommand={customCommand}
          setCustomCommand={setCustomCommand}
          isFlippedX={isFlippedX}
          setIsFlippedX={setIsFlippedX}
          isFlippedY={isFlippedY}
          setIsFlippedY={setIsFlippedY}
          isRemoteConnected={isRemoteConnected}
          backgroundColor={backgroundColor}
          onBackgroundColorChange={setBackgroundColor}
          scrollSpeechEnabled={scrollSpeechEnabled}
          onScrollSpeechEnabledChange={setScrollSpeechEnabled}
          scrollSpeechCommand={scrollSpeechCommand}
          onScrollSpeechCommandChange={setScrollSpeechCommand}
          speechError={speechError}
          transcript={transcript}
          autoHideControls={autoHideControls}
          onAutoHideControlsChange={setAutoHideControls}
        />
      </Drawer>

      <TextDisplay
        textRef={textRef}
        containerRef={containerRef}
        scrollPosition={scrollPosition}
        isFlippedX={isFlippedX}
        isFlippedY={isFlippedY}
        fontSize={fontSize}
        textColor={textColor}
        text={text}
        formattedText={formattedText}
        countdown={countdown}
        containerWidth={containerWidth}
      />

      {!isMenuOpen && (
        <Controls
          adjustPosition={adjustPosition}
          handleAdjustPosition={handleAdjustPosition}
          isPaused={isPaused}
          isScrolling={isScrolling}
          togglePauseResume={handlePauseResume}
          pauseButtonRef={pauseButtonRef}
          autoHide={autoHideControls}
        />
      )}
    </Box>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const isAuthed = await auth.checkAccess();
        setIsAuthenticated(isAuthed);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthentication();
  }, []);

  const handleLogout = () => {
    auth.logout();
    setIsAuthenticated(false);
  };

  if (isLoading) {
    return (
      <Box 
        sx={{ 
          height: '100vh', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          bgcolor: 'black'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return <LoginForm />;
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <TeleprompterApp onLogout={handleLogout} />
    </ThemeProvider>
  );
}

export default App;
