import React, { useState, useEffect, useCallback } from 'react';
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw, Modifier, ContentState, SelectionState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import './RichTextEditor.css';
import { Box, Paper } from '@mui/material';
import EditorToolbar from './editor/EditorToolbar';
import ColorPicker from './editor/ColorPicker';
import TextCorrectionDialog from './menu/dialogs/TextCorrectionDialog';
import CustomInstructionDialog from './menu/dialogs/CustomInstructionDialog';
import ErrorDialog from './menu/dialogs/ErrorDialog';
import useSpeechInput from '../hooks/useSpeechRecognition';

function RichTextEditor({ value, onChange, onCleanup, onHelpClick }) {
  const [editorState, setEditorState] = useState(() => {
    if (value) {
      try {
        if (typeof value === 'string' && value.startsWith('{')) {
          const rawContent = JSON.parse(value);
          const contentState = convertFromRaw(rawContent);
          return EditorState.createWithContent(contentState);
        }
        return EditorState.createWithText(String(value));
      } catch (e) {
        console.error('Error parsing editor content:', e);
        return EditorState.createWithText(String(value));
      }
    }
    return EditorState.createEmpty();
  });

  const [colorPickerAnchor, setColorPickerAnchor] = useState(null);
  const [currentColor, setCurrentColor] = useState('#FFFFFF');
  const [colorPickerTab, setColorPickerTab] = useState(0);
  const [correctionDialogOpen, setCorrectionDialogOpen] = useState(false);
  const [customInstructionDialogOpen, setCustomInstructionDialogOpen] = useState(false);
  const [selectedTextInfo, setSelectedTextInfo] = useState(null);
  const editor = React.useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    isListening,
    toggleRecognition,
    stopRecognition
  } = useSpeechInput(editorState, setEditorState);

  const getSelectedText = () => {
    const selection = editorState.getSelection();
    const content = editorState.getCurrentContent();
    
    if (selection.isCollapsed()) {
      return null;
    }

    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();

    return {
      text: content.getPlainText().slice(
        content.getBlockMap().takeUntil(block => block.getKey() === startKey).reduce((acc, block) => acc + block.getLength() + 1, 0) + startOffset,
        content.getBlockMap().takeUntil(block => block.getKey() === endKey).reduce((acc, block) => acc + block.getLength() + 1, 0) + endOffset
      ),
      selection: selection
    };
  };

  const handleCorrectText = () => {
    const selectedInfo = getSelectedText();
    if (!selectedInfo) {
      setErrorMessage('Bitte markieren Sie den Text, den Sie korrigieren möchten.');
      setErrorDialogOpen(true);
      return;
    }
    setSelectedTextInfo(selectedInfo);
    setCorrectionDialogOpen(true);
  };

  const handleCustomInstruction = () => {
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const selectedInfo = getSelectedText();
    
    // Wenn Text markiert ist, verwenden wir die Auswahl
    // Wenn nicht, verwenden wir den gesamten Text
    const textToProcess = selectedInfo ? selectedInfo.text : content.getPlainText();
    const textSelection = selectedInfo ? selectedInfo.selection : selection.merge({
      anchorKey: content.getFirstBlock().getKey(),
      anchorOffset: 0,
      focusKey: content.getLastBlock().getKey(),
      focusOffset: content.getLastBlock().getText().length,
      hasFocus: true
    });

    setSelectedTextInfo({
      text: textToProcess,
      selection: textSelection
    });
    setCustomInstructionDialogOpen(true);
  };

  const handleApplyCorrection = (correctedText) => {
    if (!selectedTextInfo) return;

    const contentState = editorState.getCurrentContent();
    const newContentState = Modifier.replaceText(
      contentState,
      selectedTextInfo.selection,
      correctedText
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-characters'
    );

    // Restore selection
    const newSelection = SelectionState.createEmpty(selectedTextInfo.selection.getStartKey())
      .merge({
        anchorOffset: selectedTextInfo.selection.getStartOffset(),
        focusKey: selectedTextInfo.selection.getEndKey(),
        focusOffset: selectedTextInfo.selection.getStartOffset() + correctedText.length,
      });

    setEditorState(EditorState.forceSelection(newEditorState, newSelection));
    setSelectedTextInfo(null);
  };

  const handleFocus = useCallback(() => {
    setIsFocused(true);
    if (editor.current) editor.current.focus();
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    if (isListening) {
      stopRecognition();
    }
  }, [isListening, stopRecognition]);

  const handleKeyCommand = useCallback((command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  }, []);

  const toggleInlineStyle = useCallback((style) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  }, [editorState]);

  const toggleBlockType = useCallback((blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  }, [editorState]);

  const setTextAlignment = useCallback((alignment) => {
    const selection = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(selection.getStartKey());
    const blockData = currentBlock.getData();
    
    const newAlignment = blockData.get('textAlign') === alignment ? 'left' : alignment;
    
    const newContentState = Modifier.mergeBlockData(currentContent, selection, {
      textAlign: newAlignment
    });

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'change-block-data'
    );

    setEditorState(newEditorState);
  }, [editorState]);

  const applyColor = useCallback((color) => {
    const selection = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();
    const currentStyles = editorState.getCurrentInlineStyle();
    
    let nextContentState = currentContent;

    currentStyles.forEach((style) => {
      if (style.startsWith('color-')) {
        nextContentState = Modifier.removeInlineStyle(
          nextContentState,
          selection,
          style
        );
      }
    });

    const colorStyle = `color-${color}`;
    nextContentState = Modifier.applyInlineStyle(
      nextContentState,
      selection,
      colorStyle
    );

    const newEditorState = EditorState.push(
      editorState,
      nextContentState,
      'change-inline-style'
    );

    setEditorState(newEditorState);
    setCurrentColor(color);
  }, [editorState]);

  const blockStyleFn = useCallback((contentBlock) => {
    const textAlign = contentBlock.getData().get('textAlign');
    const type = contentBlock.getType();
    let className = `text-align-${textAlign || 'left'}`;
    
    if (type.startsWith('header-')) {
      className += ` ${type}`;
    }
    
    return className;
  }, []);

  const customStyleFn = useCallback((style) => {
    const styles = {};
    style.forEach((value) => {
      if (value.startsWith('color-')) {
        styles.color = value.replace('color-', '');
      }
    });
    return styles;
  }, []);

  const getActiveStyles = useCallback(() => {
    const currentStyle = editorState.getCurrentInlineStyle();
    const selection = editorState.getSelection();
    const currentBlock = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey());
    const alignment = currentBlock.getData().get('textAlign') || 'left';
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    let activeColor = '#FFFFFF';
    currentStyle.forEach((style) => {
      if (style.startsWith('color-#')) {
        activeColor = style.replace('color-', '');
      }
    });
    
    return {
      bold: currentStyle.has('BOLD'),
      italic: currentStyle.has('ITALIC'),
      alignment: alignment,
      blockType: blockType,
      color: activeColor
    };
  }, [editorState]);

  useEffect(() => {
    if (onChange) {
      const contentState = editorState.getCurrentContent();
      const rawContent = convertToRaw(contentState);
      onChange(contentState.getPlainText(), JSON.stringify(rawContent));
    }
  }, [editorState, onChange]);

  const activeStyles = getActiveStyles();

  return (
    <Box className="editor-container" sx={{ position: 'relative' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
        <EditorToolbar
          activeStyles={activeStyles}
          toggleInlineStyle={toggleInlineStyle}
          toggleBlockType={toggleBlockType}
          setTextAlignment={setTextAlignment}
          onColorPickerOpen={(event) => setColorPickerAnchor(event.currentTarget)}
          isListening={isListening}
          toggleRecognition={toggleRecognition}
          onCorrectText={handleCorrectText}
          onCustomInstruction={handleCustomInstruction}
          onHelpClick={onHelpClick}
        />
      </Box>

      <ColorPicker
        anchorEl={colorPickerAnchor}
        onClose={() => setColorPickerAnchor(null)}
        currentColor={currentColor}
        onColorChange={applyColor}
        colorPickerTab={colorPickerTab}
        setColorPickerTab={setColorPickerTab}
      />

      <TextCorrectionDialog
        open={correctionDialogOpen}
        onClose={() => {
          setCorrectionDialogOpen(false);
          setSelectedTextInfo(null);
        }}
        text={selectedTextInfo?.text || ''}
        onApplyCorrection={handleApplyCorrection}
      />

      <CustomInstructionDialog
        open={customInstructionDialogOpen}
        onClose={() => {
          setCustomInstructionDialogOpen(false);
          setSelectedTextInfo(null);
        }}
        text={selectedTextInfo?.text || ''}
        onApplyResult={handleApplyCorrection}
      />

      <ErrorDialog
        open={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
        message={errorMessage}
      />

      <Box className="editor-content">
        <Paper 
          variant="outlined" 
          onClick={handleFocus}
          sx={{ 
            height: '100%',
            cursor: 'text',
            backgroundColor: '#000',
            color: '#fff'
          }}
        >
          <Editor
            ref={editor}
            editorState={editorState}
            onChange={setEditorState}
            handleKeyCommand={handleKeyCommand}
            blockStyleFn={blockStyleFn}
            customStyleFn={customStyleFn}
            placeholder=""
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Paper>
      </Box>
    </Box>
  );
}

export default RichTextEditor;
