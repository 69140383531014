import { useState, useEffect, useCallback } from 'react';
import auth from '../components/auth';

const POLL_INTERVAL = 1000; // Poll every second

// Hilfsfunktion für API-URLs
const getApiUrl = (endpoint) => {
  const baseUrl = auth.getBaseUrl();
  // Stelle sicher, dass baseUrl mit / endet und endpoint nicht mit / beginnt
  const base = baseUrl.endsWith('/') ? baseUrl : baseUrl + '/';
  const path = endpoint.startsWith('/') ? endpoint.slice(1) : endpoint;
  return `${base}${path}`;
};

// Prüfe ob wir in der Entwicklungsumgebung sind
const isDevelopment = process.env.NODE_ENV === 'development';

export const useRemoteControl = (onCommand) => {
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const [controlToken, setControlToken] = useState(null);

  // In der Entwicklungsumgebung simulieren wir eine erfolgreiche Verbindung
  const initializeSession = useCallback(async () => {
    if (isDevelopment) {
      console.log('Development mode: Simulating remote control connection');
      setIsConnected(true);
      setControlToken('dev-token');
      setError(null);
      return;
    }

    try {
      const url = getApiUrl('api/remote/create_session.php');
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Accept': 'application/json'
        }
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Server error');
      }
      
      if (!data.success || !data.control_token) {
        throw new Error('Invalid response data');
      }

      setControlToken(data.control_token);
      setIsConnected(true);
      setError(null);
    } catch (err) {
      console.error('Remote control error:', err);
      setError(`Failed to initialize remote control session: ${err.message}`);
      setIsConnected(false);
    }
  }, []);

  // Polling für neue Befehle
  useEffect(() => {
    let pollInterval;
    let isPolling = false;

    const pollCommands = async () => {
      // In der Entwicklungsumgebung keine Befehle pollen
      if (isDevelopment || isPolling) return;
      
      isPolling = true;

      try {
        const url = getApiUrl('api/remote/get_commands.php');
        const response = await fetch(url, {
          headers: {
            'Authorization': `Bearer ${auth.token}`,
            'Accept': 'application/json'
          }
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Server error');
        }

        if (data.success && data.commands && data.commands.length > 0) {
          data.commands.forEach(cmd => {
            if (onCommand) {
              try {
                onCommand(cmd.command);
              } catch (e) {
                console.error('Error executing command:', cmd.command, e);
              }
            }
          });
        }
      } catch (err) {
        console.error('Error polling commands:', err);
        setError(`Failed to poll commands: ${err.message}`);
      } finally {
        isPolling = false;
      }
    };

    if (isConnected) {
      pollInterval = setInterval(pollCommands, POLL_INTERVAL);
      pollCommands();
    }

    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
    };
  }, [isConnected, onCommand]);

  // Automatischer Verbindungsaufbau
  useEffect(() => {
    initializeSession();
    return () => {
      setIsConnected(false);
      setControlToken(null);
    };
  }, [initializeSession]);

  return {
    isConnected,
    error,
    controlToken,
    reconnect: initializeSession
  };
};

export const useRemoteController = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const [controlToken, setControlToken] = useState(null);

  // Initialisiere Controller Session
  const initializeSession = useCallback(async () => {
    if (isDevelopment) {
      console.log('Development mode: Simulating remote controller connection');
      setIsConnected(true);
      setControlToken('dev-token');
      setError(null);
      return;
    }

    try {
      const url = getApiUrl('api/remote/create_session.php');
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Accept': 'application/json'
        }
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Server error');
      }
      
      if (!data.success || !data.control_token) {
        throw new Error('Invalid response data');
      }

      setControlToken(data.control_token);
      setIsConnected(true);
      setError(null);
    } catch (err) {
      console.error('Remote control error:', err);
      setError(`Failed to initialize remote control session: ${err.message}`);
      setIsConnected(false);
    }
  }, []);

  // Sende Command
  const sendCommand = useCallback(async (command) => {
    if (isDevelopment) {
      console.log('Development mode: Simulating command send:', command);
      return true;
    }

    if (!isConnected || !controlToken) {
      setError('Not connected');
      return false;
    }

    try {
      const url = getApiUrl('api/remote/send_command.php');
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          command,
          control_token: controlToken
        })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to send command');
      }

      return data.success;
    } catch (err) {
      console.error('Send command error:', err);
      setError(`Failed to send command: ${err.message}`);
      return false;
    }
  }, [isConnected, controlToken]);

  // Automatischer Verbindungsaufbau
  useEffect(() => {
    initializeSession();
    return () => {
      setIsConnected(false);
      setControlToken(null);
    };
  }, [initializeSession]);

  return {
    isConnected,
    error,
    sendCommand,
    reconnect: initializeSession
  };
};
