import React from 'react';
import { Box, IconButton, Badge } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

const Header = ({ toggleMenu, toggleFullscreen, isFullscreen, isRemoteConnected }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={toggleMenu} color="inherit" sx={{ color: 'white' }}>
          <MenuIcon />
        </IconButton>
        <Badge
          variant="dot"
          color={isRemoteConnected ? "success" : "error"}
          sx={{ ml: 1, height: '24px', display: 'flex', alignItems: 'center' }}
        >
          <Box sx={{ color: 'white', fontSize: '0.875rem', lineHeight: 1 }}>
            RC
          </Box>
        </Badge>
      </Box>
      <IconButton onClick={toggleFullscreen} sx={{ color: 'white' }}>
        {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
      </IconButton>
    </Box>
  );
};

export default Header;
