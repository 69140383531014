import React, { useState } from 'react';
import { Box, IconButton, Typography, Paper, CircularProgress, ThemeProvider, createTheme } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SpeedIcon from '@mui/icons-material/Speed';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useRemoteController } from './hooks/useRemoteControl';
import LoginForm from './components/LoginForm';
import auth from './components/auth';

// Dunkles Theme erstellen
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#000000',
      paper: '#1e1e1e',
    },
  },
});

const RemoteControllerApp = ({ onLogout }) => {
  const { isConnected, error, sendCommand } = useRemoteController();
  const [lastCommand, setLastCommand] = useState('');
  const [adjustPosition, setAdjustPosition] = useState(0);

  const handleCommand = async (command) => {
    setLastCommand(command);
    await sendCommand(command);
  };

  const handleAdjustPosition = async (direction) => {
    // Ändere die Logik: 'up' erhöht die Position, 'down' verringert sie
    const newPosition = direction === 'up' ? 
      adjustPosition + 2 : 
      adjustPosition - 2;
    setAdjustPosition(newPosition);
    await handleCommand(`adjust_${newPosition}`);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ 
        minHeight: '100vh', 
        bgcolor: 'background.default', 
        color: 'text.primary',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Typography variant="h5" sx={{ mb: 3, color: 'text.primary' }}>
          Teleprompter Fernsteuerung
        </Typography>

        <Paper sx={{ 
          p: 2, 
          mb: 2, 
          bgcolor: 'background.paper',
          width: '100%',
          maxWidth: 400,
          textAlign: 'center'
        }}>
          <Typography color="text.primary">
            Status: {isConnected ? 'Verbunden' : 'Nicht verbunden'}
          </Typography>
          {error && (
            <Typography color="error" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
        </Paper>

        <Box sx={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(2, 1fr)', 
          gap: 2,
          width: '100%',
          maxWidth: 400
        }}>
          <IconButton 
            onClick={() => handleCommand('start')}
            sx={{ 
              bgcolor: 'primary.dark',
              color: 'text.primary',
              '&:hover': { bgcolor: 'primary.main' },
              height: 80
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <PlayArrowIcon sx={{ fontSize: 40 }} />
              <Typography variant="caption" display="block">
                Start
              </Typography>
            </Box>
          </IconButton>

          <IconButton 
            onClick={() => handleCommand('pause')}
            sx={{ 
              bgcolor: 'primary.dark',
              color: 'text.primary',
              '&:hover': { bgcolor: 'primary.main' },
              height: 80
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <PauseIcon sx={{ fontSize: 40 }} />
              <Typography variant="caption" display="block">
                Pause/Weiter
              </Typography>
            </Box>
          </IconButton>

          <IconButton 
            onClick={() => handleCommand('stop')}
            sx={{ 
              bgcolor: 'error.dark',
              color: 'text.primary',
              '&:hover': { bgcolor: 'error.main' },
              height: 80
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <StopIcon sx={{ fontSize: 40 }} />
              <Typography variant="caption" display="block">
                Stop
              </Typography>
            </Box>
          </IconButton>

          <IconButton 
            onClick={() => handleCommand('reset')}
            sx={{ 
              bgcolor: 'warning.dark',
              color: 'text.primary',
              '&:hover': { bgcolor: 'warning.main' },
              height: 80
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <RestartAltIcon sx={{ fontSize: 40 }} />
              <Typography variant="caption" display="block">
                Reset
              </Typography>
            </Box>
          </IconButton>

          <IconButton 
            onClick={() => handleCommand('faster')}
            sx={{ 
              bgcolor: 'success.dark',
              color: 'text.primary',
              '&:hover': { bgcolor: 'success.main' },
              height: 80
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <SpeedIcon sx={{ fontSize: 40 }} />
              <Typography variant="caption" display="block">
                Schneller
              </Typography>
            </Box>
          </IconButton>

          <IconButton 
            onClick={() => handleCommand('slower')}
            sx={{ 
              bgcolor: 'info.dark',
              color: 'text.primary',
              '&:hover': { bgcolor: 'info.main' },
              height: 80
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <SlowMotionVideoIcon sx={{ fontSize: 40 }} />
              <Typography variant="caption" display="block">
                Langsamer
              </Typography>
            </Box>
          </IconButton>

          <IconButton 
            onClick={() => handleAdjustPosition('up')}
            sx={{ 
              bgcolor: 'secondary.dark',
              color: 'text.primary',
              '&:hover': { bgcolor: 'secondary.main' },
              height: 80
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <ArrowUpwardIcon sx={{ fontSize: 40 }} />
              <Typography variant="caption" display="block">
                Position Hoch
              </Typography>
            </Box>
          </IconButton>

          <IconButton 
            onClick={() => handleAdjustPosition('down')}
            sx={{ 
              bgcolor: 'secondary.dark',
              color: 'text.primary',
              '&:hover': { bgcolor: 'secondary.main' },
              height: 80
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <ArrowDownwardIcon sx={{ fontSize: 40 }} />
              <Typography variant="caption" display="block">
                Position Runter
              </Typography>
            </Box>
          </IconButton>
        </Box>

        {lastCommand && (
          <Paper sx={{ 
            mt: 3, 
            p: 1, 
            bgcolor: 'background.paper',
            width: '100%',
            maxWidth: 400,
            textAlign: 'center'
          }}>
            <Typography color="text.primary">
              Letzter Befehl: {lastCommand}
            </Typography>
          </Paper>
        )}
      </Box>
    </ThemeProvider>
  );
};

const RemoteControlApp = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  React.useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const isAuthed = await auth.checkAccess();
        setIsAuthenticated(isAuthed);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthentication();
  }, []);

  const handleLogout = () => {
    auth.logout();
    setIsAuthenticated(false);
  };

  if (isLoading) {
    return (
      <Box 
        sx={{ 
          height: '100vh', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          bgcolor: 'black'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return <LoginForm />;
  }

  return <RemoteControllerApp onLogout={handleLogout} />;
};

export default RemoteControlApp;
