import React, { useState, useEffect } from 'react';
import { Box, IconButton, Slider } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const Controls = ({ 
  adjustPosition, 
  handleAdjustPosition, 
  isPaused, 
  isScrolling, 
  togglePauseResume, 
  pauseButtonRef,
  autoHide = false
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [timeoutId, setTimeoutId] = useState(null);

  // Funktion zum Zurücksetzen des Timeouts
  const resetTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsVisible(true);
    if (autoHide && isScrolling && !isPaused) {
      const id = setTimeout(() => setIsVisible(false), 2000);
      setTimeoutId(id);
    }
  };

  // Event-Handler für Mausbewegung und Touch
  useEffect(() => {
    if (!autoHide) {
      setIsVisible(true);
      return;
    }

    const handleInteraction = () => {
      resetTimeout();
    };

    window.addEventListener('mousemove', handleInteraction);
    window.addEventListener('touchstart', handleInteraction);

    return () => {
      window.removeEventListener('mousemove', handleInteraction);
      window.removeEventListener('touchstart', handleInteraction);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [autoHide, isScrolling, isPaused]);

  // Effekt für initiales Ausblenden
  useEffect(() => {
    if (autoHide && isScrolling && !isPaused) {
      const id = setTimeout(() => setIsVisible(false), 2000);
      setTimeoutId(id);
      return () => clearTimeout(id);
    }
  }, [autoHide, isScrolling, isPaused]);

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: 1,
        borderRadius: 1,
        zIndex: 1000,
        opacity: isVisible ? 1 : 0,
        visibility: isVisible ? 'visible' : 'hidden',
        transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
      }}
    >
      <Slider
        value={adjustPosition}
        onChange={handleAdjustPosition}
        aria-labelledby="adjust-position-slider"
        sx={{ width: 100, mr: 2 }}
        min={-100}
        max={100}
      />
      <IconButton 
        ref={pauseButtonRef}
        onClick={togglePauseResume} 
        sx={{ color: 'white' }}
      >
        {isPaused || !isScrolling ? <PlayArrowIcon /> : <PauseIcon />}
      </IconButton>
    </Box>
  );
};

export default Controls;
