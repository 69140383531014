import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Box,
  Switch,
  TextField,
  Collapse,
  Alert,
  Divider
} from '@mui/material';
import PaletteIcon from '@mui/icons-material/Palette';
import MicIcon from '@mui/icons-material/Mic';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { SketchPicker } from 'react-color';

function ColorPickerDialog({ open, onClose, color, onChange }) {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          bgcolor: 'background.paper',
          minWidth: '300px'
        }
      }}
    >
      <DialogTitle>Hintergrundfarbe wählen</DialogTitle>
      <DialogContent>
        <SketchPicker
          color={color}
          onChange={(color) => onChange(color.hex)}
          disableAlpha={true}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onChange('#000000')}>Zurücksetzen</Button>
        <Button onClick={onClose}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
}

function AppSettingsDialog({ 
  open, 
  onClose, 
  backgroundColor, 
  onBackgroundColorChange,
  scrollSpeechEnabled,
  onScrollSpeechEnabledChange,
  scrollSpeechCommand,
  onScrollSpeechCommandChange,
  speechError,
  transcript,
  autoHideControls,
  onAutoHideControlsChange
}) {
  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  const handleSpeechToggle = (e) => {
    console.log('Speech toggle clicked:', e.target.checked);
    onScrollSpeechEnabledChange(e.target.checked);
  };

  const handleCommandChange = (e) => {
    console.log('Command changed:', e.target.value);
    onScrollSpeechCommandChange(e.target.value);
  };

  const handleAutoHideToggle = (e) => {
    console.log('Auto-hide toggle clicked:', e.target.checked);
    onAutoHideControlsChange(e.target.checked);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            bgcolor: 'background.paper'
          }
        }}
      >
        <DialogTitle>App-Einstellungen</DialogTitle>
        <DialogContent>
          <List>
            <ListItem button onClick={() => setColorPickerOpen(true)}>
              <ListItemIcon>
                <PaletteIcon />
              </ListItemIcon>
              <ListItemText 
                primary="Hintergrundfarbe" 
                secondary={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box 
                      sx={{ 
                        width: 20, 
                        height: 20, 
                        bgcolor: backgroundColor,
                        border: '1px solid rgba(255, 255, 255, 0.3)',
                        borderRadius: 1
                      }} 
                    />
                    <Typography variant="body2">{backgroundColor}</Typography>
                  </Box>
                }
              />
            </ListItem>

            <Divider sx={{ my: 1 }} />

            <ListItem>
              <ListItemIcon>
                <VisibilityOffIcon />
              </ListItemIcon>
              <ListItemText 
                primary={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography>Steuerelemente automatisch ausblenden</Typography>
                    <Switch
                      checked={autoHideControls}
                      onChange={handleAutoHideToggle}
                    />
                  </Box>
                }
                secondary="Blendet Pause-Button und Slider während des Scrollens aus. Bewegen Sie die Maus oder tippen Sie auf den Bildschirm, um sie wieder einzublenden."
              />
            </ListItem>

            <Divider sx={{ my: 1 }} />

            <ListItem>
              <ListItemIcon>
                <MicIcon />
              </ListItemIcon>
              <ListItemText 
                primary={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography>Sprachsteuerung während Scrolling</Typography>
                    <Switch
                      checked={scrollSpeechEnabled}
                      onChange={handleSpeechToggle}
                    />
                  </Box>
                }
                secondary={transcript ? `Erkannt: ${transcript}` : null}
              />
            </ListItem>

            {speechError && (
              <ListItem>
                <Alert severity="error" sx={{ width: '100%' }}>
                  {speechError}
                </Alert>
              </ListItem>
            )}

            <Collapse in={scrollSpeechEnabled}>
              <ListItem sx={{ pl: 9 }}>
                <TextField
                  label="Initialwort"
                  value={scrollSpeechCommand}
                  onChange={handleCommandChange}
                  fullWidth
                  size="small"
                  helperText={`Verfügbare Befehle: "${scrollSpeechCommand} start", "${scrollSpeechCommand} stop", "${scrollSpeechCommand} pause", "${scrollSpeechCommand} weiter"`}
                />
              </ListItem>
            </Collapse>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Schließen</Button>
        </DialogActions>
      </Dialog>

      <ColorPickerDialog
        open={colorPickerOpen}
        onClose={() => setColorPickerOpen(false)}
        color={backgroundColor}
        onChange={onBackgroundColorChange}
      />
    </>
  );
}

export default AppSettingsDialog;
