import { useCallback } from 'react';
import useSpeechRecognition from './useSpeechRecognition';
import { SPEECH_ERRORS } from '../constants/speechConstants';

const useVoiceCommands = (controls) => {
  const handleCommand = useCallback((command) => {
    switch (command) {
      case 'start':
        controls.start();
        break;
      case 'stop':
        controls.stop();
        break;
      case 'faster':
        controls.increaseSpeed();
        break;
      case 'slower':
        controls.decreaseSpeed();
        break;
      case 'pause':
        controls.pause();
        break;
      case 'resume':
        controls.resume();
        break;
      case 'flip':
        controls.toggleMirror();
        break;
      default:
        console.log('Unbekanntes Sprachkommando:', command);
    }
  }, [controls]);

  const {
    isEnabled,
    listening,
    error,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
    enable,
    disable,
    toggleListening
  } = useSpeechRecognition(handleCommand);

  const getErrorMessage = () => {
    if (!browserSupportsSpeechRecognition) {
      return SPEECH_ERRORS.BROWSER_SUPPORT;
    }
    if (!isMicrophoneAvailable) {
      return SPEECH_ERRORS.MICROPHONE_ACCESS;
    }
    return error || null;
  };

  return {
    isVoiceControlEnabled: isEnabled,
    isListening: listening,
    error: getErrorMessage(),
    enableVoiceControl: enable,
    disableVoiceControl: disable,
    toggleVoiceControl: toggleListening,
  };
};

export default useVoiceCommands;
