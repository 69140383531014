import React from 'react';
import { Box, Link, Typography } from '@mui/material';

const LegalLinks = ({ onImpressumClick, onDatenschutzClick }) => {
  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      gap: 2, 
      mt: 4, 
      mb: 2,
      borderTop: '1px solid rgba(255, 255, 255, 0.12)',
      pt: 2
    }}>
      <Link
        component="button"
        variant="body2"
        onClick={onImpressumClick}
        sx={{ color: 'text.secondary' }}
      >
        Impressum
      </Link>
      <Link
        component="button"
        variant="body2"
        onClick={onDatenschutzClick}
        sx={{ color: 'text.secondary' }}
      >
        Datenschutzerklärung
      </Link>
    </Box>
  );
};

export default LegalLinks;
