import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';

const SuccessDialog = ({ open, message, onClose }) => {
  const isError = message.includes('Fehler');

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {isError ? 'Fehler' : 'Erfolg'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose} 
          color={isError ? "error" : "primary"}
          variant="contained"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
