import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import React from 'react';

export const HEADER_TYPES = [
  { label: 'Normal', style: 'unstyled' },
  { label: 'Überschrift 1', style: 'header-one' },
  { label: 'Überschrift 2', style: 'header-two' },
  { label: 'Überschrift 3', style: 'header-three' }
];

export const ALIGNMENT_OPTIONS = [
  { value: 'left', icon: <FormatAlignLeftIcon />, tooltip: 'Linksbündig' },
  { value: 'center', icon: <FormatAlignCenterIcon />, tooltip: 'Zentriert' },
  { value: 'right', icon: <FormatAlignRightIcon />, tooltip: 'Rechtsbündig' }
];

export const PRESET_COLORS = [
  { label: 'Weiß', value: '#FFFFFF' },
  { label: 'Schwarz', value: '#000000' },
  { label: 'Rot', value: '#FF0000' },
  { label: 'Grün', value: '#00FF00' },
  { label: 'Blau', value: '#0000FF' },
  { label: 'Gelb', value: '#FFFF00' },
  { label: 'Orange', value: '#FFA500' },
  { label: 'Violett', value: '#800080' }
];
