export const generateProjectName = () => {
  const timestamp = new Date().getTime();
  return `project_${timestamp}`;
};

export const saveFile = (content, fileName, fileType) => {
  const blob = new Blob([content], { type: fileType });
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const readFile = (file, onLoad) => {
  const reader = new FileReader();
  reader.onload = onLoad;
  reader.readAsText(file);
};
