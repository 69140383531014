import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import RemoteControlApp from './RemoteControlApp';
import reportWebVitals from './reportWebVitals';

// Determine which app to render based on URL parameter
const urlParams = new URLSearchParams(window.location.search);
const isRemoteControl = urlParams.get('remote') === 'true';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {isRemoteControl ? <RemoteControlApp /> : <App />}
  </React.StrictMode>
);

reportWebVitals();
