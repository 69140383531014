import config from '../config';

// Funktion zur Prüfung, ob das Modell kostenlos ist
const isModelFree = (modelId) => {
  return modelId.endsWith(':free');
};

// Funktion zur groben Schätzung der Token
const estimateTokens = (text) => {
  // Grobe Schätzung: Ein Token entspricht etwa 4 Zeichen
  return Math.ceil(text.length / 4);
};

const checkCostBeforeRequest = (text, modelKey) => {
  const modelId = config.openRouter.models[modelKey];
  
  if (!isModelFree(modelId)) {
    throw new Error('Dieses Modell ist nicht kostenlos verfügbar.');
  }

  const estimatedTokens = estimateTokens(text);
  // OpenRouter hat ein Limit von 1000 Tokens für kostenlose Anfragen
  if (estimatedTokens > 1000) {
    throw new Error('Der Text ist zu lang für eine kostenlose Anfrage. Bitte wählen Sie einen kürzeren Textabschnitt.');
  }

  return true;
};

const correctText = async (text, modelKey = 'llama3b') => {
  try {
    // Prüfung vor der API-Anfrage
    checkCostBeforeRequest(text, modelKey);

    const modelId = config.openRouter.models[modelKey];

    const response = await fetch('https://openrouter.ai/api/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_OPENROUTER_API_KEY}`,
        'HTTP-Referer': window.location.origin
      },
      body: JSON.stringify({
        model: modelId,
        messages: [
          {
            role: 'system',
            content: config.openRouter.systemPrompt
          },
          {
            role: 'user',
            content: text
          }
        ],
        temperature: 0.7,
        max_tokens: 1000
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error?.message || `API request failed: ${response.status}`);
    }

    const data = await response.json();
    return data.choices[0].message.content;
  } catch (error) {
    console.error('Error correcting text:', error);
    throw error;
  }
};

// Get available models with descriptive labels
const getAvailableModels = () => {
  const modelLabels = {
    llama3b: 'Llama 3B (schnell)',
    llama70b: 'Llama 70B (ausgewogen)',
    llama405b: 'Llama 405B (präzise)',
    mistral: 'Mistral 7B Instruct',
    phi3mini: 'Phi-3 Mini 128K Instruct',
    phi3medium: 'Phi-3 Medium 128K Instruct'
  };

  return Object.entries(modelLabels).map(([key, label]) => ({
    key,
    value: key,
    label
  }));
};

export { correctText, getAvailableModels, checkCostBeforeRequest };
