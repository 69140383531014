import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import RichTextEditor from '../../RichTextEditor';
import EditorHelpDialog from '../../editor/EditorHelpDialog';

const TextEditorDialog = ({ 
  open, 
  value, 
  onChange, 
  onClose, 
  onSave 
}) => {
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  const handleClose = () => {
    // Ensure editor cleanup happens before closing
    if (onClose) {
      onClose();
    }
  };

  const handleSave = () => {
    if (onSave) {
      onSave();
    }
  };

  const handleHelpClick = () => {
    setHelpDialogOpen(true);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Text bearbeiten</DialogTitle>
        <DialogContent>
          <RichTextEditor
            value={value}
            onChange={onChange}
            onCleanup={handleClose}
            onHelpClick={handleHelpClick}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <EditorHelpDialog 
        open={helpDialogOpen}
        onClose={() => setHelpDialogOpen(false)}
      />
    </>
  );
};

export default TextEditorDialog;
