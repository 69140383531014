import React from 'react';
import { 
  Box, 
  Typography, 
  IconButton,
  Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpIcon from '@mui/icons-material/Help';
import FileMenu from './FileMenu';

const MenuHeader = ({ 
  onHelpClick, 
  onLogout, 
  onClose, 
  onNew, 
  onSave, 
  onLoad,
  onSettingsClick
}) => {
  return (
    <>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        p: 2,
        borderBottom: '1px solid',
        borderColor: 'divider'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FileMenu 
            onNew={onNew} 
            onSave={onSave} 
            onLoad={onLoad}
            onSettingsClick={onSettingsClick}
          />
          <Typography variant="h6" sx={{ ml: 1 }}>Einstellungen</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton onClick={onHelpClick} color="primary" title="Hilfe">
            <HelpIcon />
          </IconButton>
          <IconButton onClick={onLogout} color="error" title="Abmelden">
            <LogoutIcon />
          </IconButton>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default MenuHeader;
