import config from '../config';

class Auth {
    constructor() {
        this.WP_DOMAIN = config.wpDomain;
        this.APP_ID = config.appId;
        this.APP_BASE_URL = config.baseUrl;
        this.token = localStorage.getItem('app_token');
        this.lastCheck = localStorage.getItem('last_auth_check');
        this.userId = config.isDevelopment ? 'dev-user-123' : localStorage.getItem('user_id');
    }

    setBaseUrl(baseUrl) {
        // Ensure baseUrl starts with / and ends with /
        this.APP_BASE_URL = baseUrl.startsWith('/') ? baseUrl : '/' + baseUrl;
        this.APP_BASE_URL = this.APP_BASE_URL.endsWith('/') ? this.APP_BASE_URL : this.APP_BASE_URL + '/';
    }

    getBaseUrl() {
        return this.APP_BASE_URL;
    }

    getUserId() {
        return this.userId;
    }

    async login(username, password) {
        // Development Mode: Auto-login with dummy user
        if (config.isDevelopment) {
            console.log('Development mode: Using dummy user');
            this.setToken(config.devUser.token);
            this.userId = config.devUser.username;
            this.lastCheck = Date.now().toString();
            localStorage.setItem('last_auth_check', this.lastCheck);
            localStorage.setItem('user_id', this.userId);
            return {
                token: config.devUser.token,
                user: config.devUser.username
            };
        }

        try {
            const response = await fetch(`${this.WP_DOMAIN}/wp-json/app-manager/v1/auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    password: password,
                    app_id: this.APP_ID
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Login fehlgeschlagen');
            }

            // Speichere Token und User ID
            this.setToken(data.token);
            this.userId = data.user_id || data.user;  // Unterstützt beide Formate
            this.lastCheck = Date.now().toString();
            localStorage.setItem('last_auth_check', this.lastCheck);
            localStorage.setItem('user_id', this.userId);

            return data;

        } catch (error) {
            console.error('Login Fehler:', error);
            throw error;
        }
    }

    async checkAccess() {
        try {
            if (!this.token) {
                return false;
            }

            // Development Mode: Always grant access
            if (config.isDevelopment) {
                return true;
            }

            // Prüfe ob letzte Überprüfung weniger als 5 Minuten her ist
            const now = Date.now();
            if (this.lastCheck && (now - parseInt(this.lastCheck) < 5 * 60 * 1000)) {
                return true; // Nutze Cache für 5 Minuten
            }

            const response = await fetch(`${this.WP_DOMAIN}/wp-json/app-manager/v1/check-access`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            });

            if (!response.ok) {
                throw new Error('Zugriff verweigert');
            }

            const data = await response.json();
            
            if (!data.has_access) {
                throw new Error('Keine Berechtigung für diese App');
            }

            // Aktualisiere Zeitstempel
            this.lastCheck = now.toString();
            localStorage.setItem('last_auth_check', this.lastCheck);
            
            return true;

        } catch (error) {
            console.error('Zugriffsprüfung fehlgeschlagen:', error);
            this.logout();
            return false;
        }
    }

    logout() {
        this.token = null;
        this.lastCheck = null;
        this.userId = null;
        localStorage.removeItem('app_token');
        localStorage.removeItem('last_auth_check');
        localStorage.removeItem('user_id');
        window.location.href = this.getBaseUrl();
    }

    setToken(token) {
        this.token = token;
        localStorage.setItem('app_token', token);
    }

    isLoggedIn() {
        return !!this.token;
    }
}

// Exportiere eine einzelne Instanz
const auth = new Auth();
export default auth;
