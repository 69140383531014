import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Slider,
  IconButton,
  Tooltip
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const SliderWithLock = ({ 
  title, 
  value, 
  onChange, 
  min, 
  max, 
  step = 1, 
  unit = '',
  isLocked,
  onToggleLock,
  settingName
}) => {
  return (
    <Box sx={{ my: 2, position: 'relative' }}>
      <Typography gutterBottom>
        {title}: {value}{unit}
      </Typography>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: 1
      }}>
        <Box sx={{ 
          flexGrow: 1,
          position: 'relative',
          opacity: isLocked ? 0.5 : 1
        }}>
          {isLocked && (
            <Box
              sx={{
                position: 'absolute',
                top: -8,
                left: 0,
                right: 0,
                bottom: -8,
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                zIndex: 2,
                cursor: 'not-allowed',
                pointerEvents: 'all'
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          )}
          <Slider
            value={value}
            onChange={onChange}
            disabled={isLocked}
            aria-labelledby={`${title}-slider`}
            valueLabelDisplay="auto"
            step={step}
            marks
            min={min}
            max={max}
            sx={{
              '& .MuiSlider-thumb': {
                visibility: isLocked ? 'hidden' : 'visible'
              }
            }}
          />
        </Box>
        <Box>
          <Tooltip title={isLocked ? "Entsperren" : "Sperren"}>
            <IconButton 
              onClick={onToggleLock}
              size="small"
              color={isLocked ? "primary" : "default"}
              sx={{ 
                ml: 1,
                zIndex: 3
              }}
            >
              {isLocked ? <LockIcon /> : <LockOpenIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

const SettingsControls = ({
  fontSize,
  onFontSizeChange,
  speed,
  onSpeedChange,
  startDelay,
  onStartDelayChange,
  containerWidth,
  onContainerWidthChange,
  bypassLock = false
}) => {
  const [lockedSettings, setLockedSettings] = useState({
    fontSize: true,
    speed: true,
    startDelay: true,
    containerWidth: true
  });

  const handleToggleLock = (setting) => {
    setLockedSettings(prev => ({
      ...prev,
      [setting]: !prev[setting]
    }));
  };

  const handleSliderChange = (handler, setting) => (event, newValue) => {
    // Only allow changes if unlocked OR if remote control is active
    if (!lockedSettings[setting] || bypassLock) {
      handler(newValue);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Einstellungen
      </Typography>

      <SliderWithLock
        title="Schriftgröße"
        value={fontSize}
        onChange={handleSliderChange(onFontSizeChange, 'fontSize')}
        min={12}
        max={72}
        step={1}
        unit="px"
        isLocked={lockedSettings.fontSize}
        onToggleLock={() => handleToggleLock('fontSize')}
        settingName="fontSize"
      />

      <SliderWithLock
        title="Scrollgeschwindigkeit"
        value={speed}
        onChange={handleSliderChange(onSpeedChange, 'speed')}
        min={1}
        max={100}
        step={1}
        isLocked={lockedSettings.speed}
        onToggleLock={() => handleToggleLock('speed')}
        settingName="speed"
      />

      <SliderWithLock
        title="Startverzögerung"
        value={startDelay}
        onChange={handleSliderChange(onStartDelayChange, 'startDelay')}
        min={0}
        max={10}
        step={1}
        unit=" Sekunden"
        isLocked={lockedSettings.startDelay}
        onToggleLock={() => handleToggleLock('startDelay')}
        settingName="startDelay"
      />

      <SliderWithLock
        title="Textbreite"
        value={containerWidth}
        onChange={handleSliderChange(onContainerWidthChange, 'containerWidth')}
        min={30}
        max={95}
        step={1}
        unit="%"
        isLocked={lockedSettings.containerWidth}
        onToggleLock={() => handleToggleLock('containerWidth')}
        settingName="containerWidth"
      />
    </Box>
  );
};

export default SettingsControls;
