import React, { useState, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { EditorState, ContentState, Modifier, SelectionState } from 'draft-js';
import 'regenerator-runtime/runtime';

const useSpeechInput = (editorState, setEditorState) => {
  const [previousTranscript, setPreviousTranscript] = useState('');
  const [transcriptBuffer, setTranscriptBuffer] = useState('');
  const BUFFER_TIMEOUT = 300; // Millisekunden

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable
  } = useSpeechRecognition({
    continuous: true,
    interimResults: true,
    onResult: (event) => {
      const results = event.results;
      if (results && results.length > 0) {
        const lastResult = results[results.length - 1];
        if (lastResult.isFinal && lastResult[0].confidence > 0.7) {
          updateEditorContent(lastResult[0].transcript);
        }
      }
    }
  });

  const updateEditorContent = (newText) => {
    if (!newText || !editorState || !setEditorState) return;

    try {
      // Nur den neuen Teil des Textes verarbeiten
      const newPortion = newText.slice(previousTranscript.length).trim();
      if (!newPortion) return;

      // Verbesserte Wortgrenzenerkennung
      const words = newPortion.split(/\s+/);
      const cleanedText = words
        .filter(word => word.length > 0)
        .join(' ');

      // Aktuellen Content holen
      const currentContent = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const currentText = currentContent.getPlainText();

      // Intelligentere Leerzeichenbehandlung
      let finalText = cleanedText;
      if (currentText.length > 0 && 
          !currentText.endsWith(' ') && 
          !finalText.startsWith(' ') &&
          !/[.,!?]$/.test(currentText)) {
        finalText = ' ' + finalText;
      }

      // Neuen Text einfügen
      const newContent = Modifier.insertText(
        currentContent,
        selection.merge({
          anchorOffset: currentText.length,
          focusOffset: currentText.length
        }),
        finalText
      );

      // Neuen EditorState erstellen
      const newEditorState = EditorState.push(
        editorState,
        newContent,
        'insert-characters'
      );

      // Cursor ans Ende setzen
      const newSelection = SelectionState.createEmpty(newContent.getBlockMap().last().getKey())
        .merge({
          anchorOffset: newContent.getPlainText().length,
          focusOffset: newContent.getPlainText().length
        });

      const finalEditorState = EditorState.forceSelection(newEditorState, newSelection);

      setEditorState(finalEditorState);
      setPreviousTranscript(newText);
    } catch (error) {
      console.error('Error updating editor content:', error);
    }
  };

  useEffect(() => {
    if (listening && transcript) {
      const timeoutId = setTimeout(() => {
        setTranscriptBuffer(transcript);
        updateEditorContent(transcript);
      }, BUFFER_TIMEOUT);
      
      return () => clearTimeout(timeoutId);
    }
  }, [transcript, listening]);

  const toggleRecognition = () => {
    if (!browserSupportsSpeechRecognition) {
      alert('Spracherkennung wird in diesem Browser nicht unterstützt.');
      return;
    }

    if (!isMicrophoneAvailable) {
      alert('Bitte erlauben Sie den Zugriff auf das Mikrofon.');
      return;
    }

    if (!listening) {
      resetTranscript();
      setPreviousTranscript('');
      setTranscriptBuffer('');
      SpeechRecognition.startListening({ 
        continuous: true,
        interimResults: true,
        language: 'de-DE',
        maxAlternatives: 1,
        speechRecognitionProperties: {
          interimResults: true,
          maxAlternatives: 1,
          continuous: true
        }
      });
    } else {
      SpeechRecognition.stopListening();
      resetTranscript();
      setPreviousTranscript('');
      setTranscriptBuffer('');
    }
  };

  const stopRecognition = () => {
    if (listening) {
      SpeechRecognition.stopListening();
      resetTranscript();
      setPreviousTranscript('');
      setTranscriptBuffer('');
    }
  };

  return { 
    isListening: listening, 
    toggleRecognition,
    stopRecognition,
    currentBuffer: transcriptBuffer // Optional: Falls du den Buffer-Status überwachen möchtest
  };
};

export default useSpeechInput;
