const isDevelopment = process.env.NODE_ENV === 'development';

const config = {
    // Basis-URL der App
    baseUrl: isDevelopment ? '/' : '/',
    
    // WordPress API Domain
    wpDomain: 'https://manage.videngo.com',
    
    // Storage API URL (ohne trailing slash)
    storageApiUrl: isDevelopment 
        ? 'http://localhost/teleprompter-app/api/texts'
        : 'https://teleprompter.videngo.com/api/texts',

    // LLM API URL
    llmApiUrl: isDevelopment
        ? 'http://localhost/teleprompter-app/api/llm'
        : 'https://teleprompter.videngo.com/api/llm',
    
    // App ID
    appId: 35,

    // OpenRouter Configuration
    openRouter: {
        // Available models (all free)
        models: {
            llama3b: 'meta-llama/llama-3.2-3b-instruct:free',
            llama70b: 'meta-llama/llama-3.1-70b-instruct:free',
            llama405b: 'meta-llama/llama-3.1-405b-instruct:free',
            mistral: 'mistralai/mistral-7b-instruct:free',
            phi3mini: 'microsoft/phi-3-mini:free',
            phi3medium: 'microsoft/phi-3-medium:free'
        },
        // Default model to use (starting with smallest one as default)
        defaultModel: 'meta-llama/llama-3.2-3b-instruct:free',
        // System prompt for text correction
        systemPrompt: 'Du bist ein Assistent, der Text korrigiert. Korrigiere Grammatik, Rechtschreibung und Zeichensetzung. Gib nur den korrigierten Text zurück, ohne zusätzliche Erklärungen.'
    },

    // Development Mode Settings
    isDevelopment: isDevelopment,
    devUser: {
        token: 'dummy-dev-token-123',
        username: 'dev-user'
    }
};

// Debug logging
console.log('Environment:', process.env.NODE_ENV);
console.log('Storage API URL:', config.storageApiUrl);
console.log('LLM API URL:', config.llmApiUrl);

export default config;
