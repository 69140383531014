import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Box,
  CircularProgress,
  Paper,
  Alert,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getAvailableModels, checkCostBeforeRequest } from '../../../api/openRouterService';
import config from '../../../config';

// Styled components
const StyledSelect = styled(Select)({
  backgroundColor: '#424242',
  color: '#fff',
  '& .MuiSelect-icon': {
    color: '#fff',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.23)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.5)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fff',
  },
});

const StyledMenuItem = styled(MenuItem)({
  '&.MuiMenuItem-root': {
    color: '#fff',
    backgroundColor: '#424242',
    '&:hover': {
      backgroundColor: '#505050',
    },
    '&.Mui-selected': {
      backgroundColor: '#606060',
      '&:hover': {
        backgroundColor: '#707070',
      },
    },
  },
});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    color: '#fff',
    backgroundColor: '#424242',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fff',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#fff',
  },
});

const TextPreview = styled(Paper)({
  padding: '16px',
  marginTop: '16px',
  backgroundColor: '#1a1a1a',
  color: '#fff',
  maxHeight: '200px',
  overflowY: 'auto',
  '&.result': {
    backgroundColor: '#263238',
  }
});

function CustomInstructionDialog({ open, onClose, text, onApplyResult }) {
  const [selectedModel, setSelectedModel] = useState('llama3b');
  const [instruction, setInstruction] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState('');
  const [error, setError] = useState('');

  const handleProcess = async () => {
    if (!instruction.trim()) {
      setError('Bitte geben Sie eine Anweisung ein.');
      return;
    }

    try {
      try {
        checkCostBeforeRequest(text, selectedModel);
      } catch (costError) {
        setError(costError.message);
        return;
      }

      setIsLoading(true);
      setError('');
      
      const modelId = config.openRouter.models[selectedModel];
      
      const response = await fetch('https://openrouter.ai/api/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_OPENROUTER_API_KEY}`,
          'HTTP-Referer': window.location.origin
        },
        body: JSON.stringify({
          model: modelId,
          messages: [
            {
              role: 'system',
              content: instruction
            },
            {
              role: 'user',
              content: text
            }
          ],
          temperature: 0.7,
          max_tokens: 1000
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error?.message || `Request failed: ${response.status}`);
      }

      const data = await response.json();
      setResult(data.choices[0].message.content);
    } catch (error) {
      console.error('Error:', error);
      setError('Fehler bei der Verarbeitung. Bitte versuchen Sie es später erneut.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleApply = () => {
    onApplyResult(result);
    handleClose();
  };

  const handleClose = () => {
    setResult('');
    setError('');
    setInstruction('');
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: '#2d2d2d',
          color: '#fff'
        }
      }}
    >
      <DialogTitle>Text mit KI verarbeiten</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Wählen Sie ein Modell:
          </Typography>
          <FormControl fullWidth>
            <StyledSelect
              value={selectedModel}
              onChange={(e) => setSelectedModel(e.target.value)}
            >
              {getAvailableModels().map(model => (
                <StyledMenuItem key={model.key} value={model.key}>
                  {model.label}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Ihre Anweisung:
          </Typography>
          <StyledTextField
            fullWidth
            multiline
            rows={3}
            value={instruction}
            onChange={(e) => setInstruction(e.target.value)}
            placeholder="z.B.: Fasse den Text zusammen, Übersetze den Text ins Englische, etc."
          />
        </Box>

        <Typography variant="subtitle1">Ausgewählter Text:</Typography>
        <TextPreview>
          {text}
        </TextPreview>

        {result && (
          <>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>Ergebnis:</Typography>
            <TextPreview className="result">
              {result}
            </TextPreview>
          </>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={handleClose} color="inherit">
          Abbrechen
        </Button>
        {!result ? (
          <Button 
            onClick={handleProcess} 
            variant="contained" 
            color="primary"
            disabled={isLoading || !instruction.trim()}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Verarbeiten'}
          </Button>
        ) : (
          <Button 
            onClick={handleApply} 
            variant="contained" 
            color="primary"
          >
            Übernehmen
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default CustomInstructionDialog;
