import { useState, useEffect, useRef } from 'react';

function useTeleprompter(speed, isFlippedY = false, isFlippedX = false) {
  const [isScrolling, setIsScrolling] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [countdown, setCountdown] = useState(0);
  const textRef = useRef(null);
  const containerRef = useRef(null);
  const animationRef = useRef(null);
  const pausedPositionRef = useRef(null);
  const pausedProgressRef = useRef(null);

  // Hier können Sie die Geschwindigkeitseinstellungen anpassen:
  const calculateSpeed = (speedValue) => {
    // Minimale Geschwindigkeit (Pixel pro Sekunde) - für langsameres Scrollen verringern
    const minSpeed = 5;   // Ursprünglich 20, jetzt langsamer
    
    // Maximale Geschwindigkeit (Pixel pro Sekunde) - für schnelleres Scrollen erhöhen
    const maxSpeed = 250;  // Ursprünglich 200, jetzt schneller
    
    // Nicht-lineare Interpolation für feinere Kontrolle bei niedrigen Geschwindigkeiten
    const normalizedSpeed = (speedValue - 1) / 99;  // 0 bis 1
    
    // Exponentialfaktor - für noch feinere Kontrolle bei niedrigen Geschwindigkeiten erhöhen
    // Für weniger Unterschied zwischen langsam und schnell verringern
    const exponentialFactor = Math.pow(normalizedSpeed, 1.8);  // Ursprünglich 1.5
    
    return minSpeed + (maxSpeed - minSpeed) * exponentialFactor;
  };

  // Rest des Codes bleibt unverändert...
  const calculateDuration = (distance, speedValue) => {
    const pixelsPerSecond = calculateSpeed(speedValue);
    return Math.abs(distance) / pixelsPerSecond;
  };

  const startAnimation = (startPos = null, progress = 0) => {
    if (!textRef.current || !containerRef.current) return;

    const totalHeight = textRef.current.clientHeight;
    const viewportHeight = containerRef.current.clientHeight;
    
    const defaultStartPos = isFlippedY ? -totalHeight : viewportHeight;
    const startPosition = startPos !== null ? startPos : defaultStartPos;
    const endPos = isFlippedY ? totalHeight : -(totalHeight + viewportHeight);
    
    // Berechne die Gesamtdistanz und daraus die Dauer
    const totalDistance = Math.abs(endPos - startPosition);
    const duration = calculateDuration(totalDistance, speed);
    
    // Setze Startposition ohne Transition
    textRef.current.style.transition = 'none';
    textRef.current.style.transform = `translate3d(-50%, ${startPosition}px, 0) ${isFlippedX ? 'scaleX(-1)' : ''} ${isFlippedY ? 'scaleY(-1)' : ''}`;
    
    // Force reflow
    const height = textRef.current.offsetHeight;
    
    // Setze die Animation
    const remainingDuration = duration * (1 - progress);
    textRef.current.style.transition = `transform ${remainingDuration}s linear`;
    textRef.current.style.transform = `translate3d(-50%, ${endPos}px, 0) ${isFlippedX ? 'scaleX(-1)' : ''} ${isFlippedY ? 'scaleY(-1)' : ''}`;
    
    const handleAnimationEnd = () => {
      setIsScrolling(false);
      if (textRef.current) {
        textRef.current.style.transition = 'none';
        textRef.current.style.transform = `translate3d(-50%, ${defaultStartPos}px, 0) ${isFlippedX ? 'scaleX(-1)' : ''} ${isFlippedY ? 'scaleY(-1)' : ''}`;
      }
    };

    textRef.current.addEventListener('transitionend', handleAnimationEnd);
    animationRef.current = handleAnimationEnd;
  };

  // Rest des Codes bleibt unverändert...
  useEffect(() => {
    if (isScrolling && !isPaused && textRef.current) {
      const computedStyle = window.getComputedStyle(textRef.current);
      const matrix = new DOMMatrix(computedStyle.transform);
      const currentPos = matrix.m42;

      const totalHeight = textRef.current.clientHeight;
      const viewportHeight = containerRef.current.clientHeight;
      const startPos = isFlippedY ? -totalHeight : viewportHeight;
      const endPos = isFlippedY ? totalHeight : -(totalHeight + viewportHeight);
      
      const totalDistance = Math.abs(endPos - startPos);
      const coveredDistance = Math.abs(currentPos - startPos);
      const progress = coveredDistance / totalDistance;

      startAnimation(currentPos, progress);
    }
  }, [speed]);

  useEffect(() => {
    let countdownId;

    if (countdown > 0) {
      countdownId = setInterval(() => {
        setCountdown(prev => {
          if (prev === 1) {
            setIsScrolling(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else if (isScrolling && !isPaused && textRef.current) {
      if (pausedPositionRef.current !== null && pausedProgressRef.current !== null) {
        startAnimation(pausedPositionRef.current, pausedProgressRef.current);
        pausedPositionRef.current = null;
        pausedProgressRef.current = null;
      } else {
        startAnimation();
      }
    }

    return () => {
      clearInterval(countdownId);
      if (textRef.current && animationRef.current) {
        textRef.current.removeEventListener('transitionend', animationRef.current);
      }
    };
  }, [countdown, isScrolling, isPaused]);

  const start = (startDelay) => {
    if (!textRef.current || !containerRef.current) return;

    const startPos = isFlippedY ? -textRef.current.clientHeight : containerRef.current.clientHeight;
    textRef.current.style.transition = 'none';
    textRef.current.style.transform = `translate3d(-50%, ${startPos}px, 0) ${isFlippedX ? 'scaleX(-1)' : ''} ${isFlippedY ? 'scaleY(-1)' : ''}`;
    
    pausedPositionRef.current = null;
    pausedProgressRef.current = null;
    setIsPaused(false);
    if (startDelay > 0) {
      setCountdown(startDelay);
    } else {
      setIsScrolling(true);
    }
  };

  const stop = () => {
    setIsScrolling(false);
    setIsPaused(false);
    setCountdown(0);
    pausedPositionRef.current = null;
    pausedProgressRef.current = null;
    
    if (textRef.current && containerRef.current) {
      const resetPos = isFlippedY ? -textRef.current.clientHeight : containerRef.current.clientHeight;
      textRef.current.style.transition = 'none';
      textRef.current.style.transform = `translate3d(-50%, ${resetPos}px, 0) ${isFlippedX ? 'scaleX(-1)' : ''} ${isFlippedY ? 'scaleY(-1)' : ''}`;
    }
  };

  const pause = () => {
    if (isScrolling && textRef.current && containerRef.current) {
      // Hole aktuelle Position
      const computedStyle = window.getComputedStyle(textRef.current);
      const matrix = new DOMMatrix(computedStyle.transform);
      const currentPos = matrix.m42;

      // Berechne den Fortschritt
      const totalHeight = textRef.current.clientHeight;
      const viewportHeight = containerRef.current.clientHeight;
      const startPos = isFlippedY ? -totalHeight : viewportHeight;
      const endPos = isFlippedY ? totalHeight : -(totalHeight + viewportHeight);
      
      const totalDistance = Math.abs(endPos - startPos);
      const coveredDistance = Math.abs(currentPos - startPos);
      const progress = coveredDistance / totalDistance;

      // Speichere Position und Fortschritt
      pausedPositionRef.current = currentPos;
      pausedProgressRef.current = progress;

      // Stoppe die Animation
      textRef.current.style.transition = 'none';
      textRef.current.style.transform = `translate3d(-50%, ${currentPos}px, 0) ${isFlippedX ? 'scaleX(-1)' : ''} ${isFlippedY ? 'scaleY(-1)' : ''}`;
      
      setIsPaused(true);
    }
  };

  const resume = () => {
    if (isPaused) {
      setIsPaused(false);
      setIsScrolling(true);
    }
  };

  const togglePauseResume = () => {
    if (isPaused) {
      resume();
    } else {
      pause();
    }
  };

  const adjustScrollPosition = (newPosition) => {
    if (textRef.current) {
      textRef.current.style.transition = 'none';
      textRef.current.style.transform = `translate3d(-50%, ${newPosition}px, 0) ${isFlippedX ? 'scaleX(-1)' : ''} ${isFlippedY ? 'scaleY(-1)' : ''}`;
      setScrollPosition(newPosition);

      // Wenn pausiert, aktualisiere die gespeicherte Position
      if (isPaused) {
        const totalHeight = textRef.current.clientHeight;
        const viewportHeight = containerRef.current.clientHeight;
        const startPos = isFlippedY ? -totalHeight : viewportHeight;
        const endPos = isFlippedY ? totalHeight : -(totalHeight + viewportHeight);
        
        const totalDistance = Math.abs(endPos - startPos);
        const coveredDistance = Math.abs(newPosition - startPos);
        const progress = coveredDistance / totalDistance;

        pausedPositionRef.current = newPosition;
        pausedProgressRef.current = progress;
      }
    }
  };

  return {
    isScrolling,
    isPaused,
    scrollPosition,
    countdown,
    textRef,
    containerRef,
    start,
    stop,
    pause,
    resume,
    togglePauseResume,
    setScrollPosition: adjustScrollPosition
  };
}

export default useTeleprompter;
