import React from 'react';
import { 
  Box, 
  Tooltip, 
  ToggleButton 
} from '@mui/material';
import FlipIcon from '@mui/icons-material/Flip';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const FlipControls = ({
  isFlippedX,
  onFlipX,
  isFlippedY,
  onFlipY
}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, my: 2 }}>
      <ToggleButton
        value="flipX"
        selected={isFlippedX}
        onChange={onFlipX}
        sx={{ color: 'white', borderColor: '#444' }}
      >
        <Tooltip title="Horizontal spiegeln">
          <FlipIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton
        value="flipY"
        selected={isFlippedY}
        onChange={onFlipY}
        sx={{ color: 'white', borderColor: '#444' }}
      >
        <Tooltip title="Vertikal spiegeln">
          <SwapVertIcon />
        </Tooltip>
      </ToggleButton>
    </Box>
  );
};

export default FlipControls;
