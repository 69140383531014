import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  Typography, 
  Box, 
  List, 
  ListItem, 
  ListItemText, 
  IconButton,
  Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function EditorHelpDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2, pr: 6 }}>
        Editor-Hilfe
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box mb={4}>
          <Typography variant="h6" gutterBottom color="primary">
            KI-Textkorrektur
          </Typography>
          <Typography paragraph>
            Die KI-Textkorrektur hilft Ihnen dabei, Ihren Text zu verbessern und Fehler zu korrigieren.
          </Typography>
          <List dense>
            <ListItem>
              <ListItemText 
                primary="Automatische Korrektur"
                secondary="Klicken Sie auf den Zauberstab-Button, um den Text automatisch korrigieren zu lassen. Die KI prüft auf Rechtschreibung, Grammatik und verbessert den Textfluss."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Vorschläge"
                secondary="Die KI macht Vorschläge zur Verbesserung, die Sie annehmen oder ablehnen können."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Formatierung"
                secondary="Die Korrektur behält Ihre Textformatierungen bei."
              />
            </ListItem>
          </List>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box mb={4}>
          <Typography variant="h6" gutterBottom color="primary">
            Verfügbare KI-Modelle
          </Typography>
          <List dense>
            <ListItem>
              <ListItemText 
                primary="Llama 3B (schnell)"
                secondary="Ein kompaktes, schnelles Modell für einfache Textverarbeitungen. Ideal für kurze Texte und schnelle Korrekturen."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Llama 70B (ausgewogen)"
                secondary="Ausgewogenes Modell mit gutem Verhältnis zwischen Geschwindigkeit und Qualität. Empfohlen für die meisten Anwendungsfälle."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Llama 405B (präzise)"
                secondary="Größtes und präzisestes Modell. Ideal für komplexe Texte und wenn höchste Qualität gefordert ist."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Mistral 7B Instruct"
                secondary="Speziell für Anweisungen optimiertes Modell. Sehr gut für spezifische Textanpassungen und Formatierungen."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Phi-3 Mini 128K"
                secondary="Kompaktes Modell mit guter Leistung für kurze Texte. Schnell und effizient für einfache Aufgaben."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Phi-3 Medium 128K"
                secondary="Mittelgroßes Modell mit erweiterter Kontextverarbeitung. Guter Kompromiss zwischen Geschwindigkeit und Leistung."
              />
            </ListItem>
          </List>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box mb={4}>
          <Typography variant="h6" gutterBottom color="primary">
            Benutzerdefinierte KI-Anweisungen
          </Typography>
          <Typography paragraph>
            Mit den benutzerdefinierten KI-Anweisungen können Sie die Textverarbeitung nach Ihren Wünschen steuern.
          </Typography>
          <List dense>
            <ListItem>
              <ListItemText 
                primary="Anweisungen geben"
                secondary="Klicken Sie auf das Gehirn-Symbol und geben Sie Ihre spezifischen Anweisungen ein, wie die KI Ihren Text verarbeiten soll."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Beispiele für Anweisungen"
                secondary={`
                  • "Mache den Text formeller"
                  • "Vereinfache die Sprache"
                  • "Kürze den Text auf die wichtigsten Punkte"
                  • "Passe den Text für eine Präsentation an"
                `}
              />
            </ListItem>
          </List>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" gutterBottom color="primary">
          Editor-Funktionen
        </Typography>
        <List dense>
          <ListItem>
            <ListItemText 
              primary="Textformatierung"
              secondary="Nutzen Sie die Formatierungsoptionen (Fett, Kursiv) für bessere Lesbarkeit und Hervorhebungen."
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Ausrichtung"
              secondary="Wählen Sie zwischen linksbündig, zentriert und rechtsbündig für die optimale Textdarstellung."
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Spracherkennung"
              secondary="Aktivieren Sie die Spracherkennung, um Text per Spracheingabe zu erfassen."
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Textfarbe"
              secondary="Passen Sie die Textfarbe an, um wichtige Passagen hervorzuheben."
            />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom color="primary" sx={{ mt: 3 }}>
          Tipps
        </Typography>
        <List dense>
          <ListItem>
            <ListItemText 
              primary="Modellauswahl"
              secondary="Wählen Sie für kurze Texte und schnelle Korrekturen die schnelleren Modelle (3B, Mini). Für wichtige oder komplexe Texte nutzen Sie die präziseren Modelle (405B, Medium)."
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="KI-Anweisungen"
              secondary="Je präziser Ihre Anweisungen, desto besser das Ergebnis der KI-Verarbeitung."
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Überprüfung"
              secondary="Überprüfen Sie die KI-Vorschläge immer, bevor Sie sie übernehmen."
            />
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
}

export default EditorHelpDialog; 