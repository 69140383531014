import { useState, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { SPEECH_ERRORS } from '../constants/speechConstants';

const useScrollSpeechRecognition = (isEnabled, baseCommand = 'achtung', onPauseResume, onStart, onStop) => {
  const [error, setError] = useState(null);
  const [wasEnabled, setWasEnabled] = useState(false);

  const commands = [
    {
      command: `${baseCommand} start`,
      callback: () => {
        console.log('Scroll Recognition - Executing start');
        onStart();
      }
    },
    {
      command: [`${baseCommand} stop`, `${baseCommand} stopp`],
      callback: () => {
        console.log('Scroll Recognition - Executing stop');
        onStop();
      }
    },
    {
      command: `${baseCommand} pause`,
      callback: () => {
        console.log('Scroll Recognition - Executing pause');
        onPauseResume();
      }
    },
    {
      command: `${baseCommand} weiter`,
      callback: () => {
        console.log('Scroll Recognition - Executing resume');
        onPauseResume();
      }
    }
  ];

  const {
    transcript,
    listening,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable
  } = useSpeechRecognition({ commands });

  // Only handle our own enable/disable state
  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      setError(SPEECH_ERRORS.BROWSER_SUPPORT);
      return;
    }

    if (!isMicrophoneAvailable) {
      setError(SPEECH_ERRORS.MICROPHONE_ACCESS);
      return;
    }

    // Only start/stop based on our isEnabled prop changes
    if (isEnabled !== wasEnabled) {
      if (isEnabled) {
        try {
          console.log('Scroll Recognition - Starting');
          SpeechRecognition.startListening({ 
            continuous: true,
            language: 'de-DE'
          });
        } catch (err) {
          console.error('Scroll Recognition - Start Error:', err);
          setError('Fehler beim Starten der Spracherkennung');
        }
      } else {
        try {
          console.log('Scroll Recognition - Stopping');
          SpeechRecognition.stopListening();
        } catch (err) {
          console.error('Scroll Recognition - Stop Error:', err);
          setError('Fehler beim Stoppen der Spracherkennung');
        }
      }
      setWasEnabled(isEnabled);
    }
  }, [isEnabled, wasEnabled, browserSupportsSpeechRecognition, isMicrophoneAvailable]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (wasEnabled) {
        SpeechRecognition.stopListening();
      }
    };
  }, [wasEnabled]);

  return {
    isListening: listening && wasEnabled,
    error,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
    transcript
  };
};

export default useScrollSpeechRecognition;
