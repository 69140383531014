import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';

const OverwriteDialog = ({ 
  open, 
  projectName, 
  onCancel, 
  onCreateNew, 
  onOverwrite 
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Text existiert bereits</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ein Text mit dem Namen "{projectName}" existiert bereits. 
          Was möchten Sie tun?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          Abbrechen
        </Button>
        <Button onClick={onCreateNew} color="primary">
          Als neu speichern
        </Button>
        <Button onClick={onOverwrite} color="error">
          Überschreiben
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OverwriteDialog;
