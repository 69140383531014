import auth from '../components/auth';
import config from '../config';

class TextService {
    static async checkExists(projectName) {
        try {
            const url = new URL(`${config.storageApiUrl}/list.php`);
            url.searchParams.append('project_name', projectName);
            
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`,
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to check text existence');
            }

            const data = await response.json();
            return data.length > 0 ? data[0] : null;
        } catch (error) {
            console.error('Error checking text existence:', error);
            throw error;
        }
    }

    static async createText(text, overwrite = false) {
        try {
            console.log('Creating text:', text, 'overwrite:', overwrite);
            
            const response = await fetch(`${config.storageApiUrl}/create.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`,
                    'Accept': 'application/json'
                },
                body: JSON.stringify({ ...text, overwrite })
            });

            console.log('Response status:', response.status);
            const responseData = await response.json();
            console.log('Response data:', responseData);

            if (!responseData.success) {
                if (responseData.error === 'exists') {
                    throw { code: 'EXISTS', message: 'Text already exists' };
                }
                throw new Error(responseData.error || 'Failed to save text');
            }

            return responseData;
        } catch (error) {
            console.error('Error saving text:', error);
            throw error;
        }
    }

    static async getTexts(search = '') {
        try {
            console.log('Getting texts with token:', auth.token);
            
            const url = new URL(`${config.storageApiUrl}/list.php`);
            if (search) {
                url.searchParams.append('search', search);
            }
            
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`,
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch texts');
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching texts:', error);
            throw error;
        }
    }

    static async deleteText(id) {
        try {
            const response = await fetch(`${config.storageApiUrl}/delete.php?id=${id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${auth.token}`,
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to delete text');
            }

            return true;
        } catch (error) {
            console.error('Error deleting text:', error);
            throw error;
        }
    }
}

export default TextService;
