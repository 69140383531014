import React from 'react';
import { Box, IconButton, Tooltip, Divider } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import PsychologyIcon from '@mui/icons-material/Psychology';

function EditorToolbar({
  activeStyles,
  toggleInlineStyle,
  toggleBlockType,
  setTextAlignment,
  onColorPickerOpen,
  isListening,
  toggleRecognition,
  onCorrectText,
  onCustomInstruction,
  onHelpClick
}) {
  return (
    <Box className="editor-toolbar" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
      <Tooltip title="Fett">
        <IconButton 
          onClick={() => toggleInlineStyle('BOLD')}
          color={activeStyles.bold ? 'primary' : 'default'}
        >
          <FormatBoldIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Kursiv">
        <IconButton
          onClick={() => toggleInlineStyle('ITALIC')}
          color={activeStyles.italic ? 'primary' : 'default'}
        >
          <FormatItalicIcon />
        </IconButton>
      </Tooltip>

      <Divider orientation="vertical" flexItem />

      <Tooltip title="Linksbündig">
        <IconButton
          onClick={() => setTextAlignment('left')}
          color={activeStyles.alignment === 'left' ? 'primary' : 'default'}
        >
          <FormatAlignLeftIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Zentriert">
        <IconButton
          onClick={() => setTextAlignment('center')}
          color={activeStyles.alignment === 'center' ? 'primary' : 'default'}
        >
          <FormatAlignCenterIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Rechtsbündig">
        <IconButton
          onClick={() => setTextAlignment('right')}
          color={activeStyles.alignment === 'right' ? 'primary' : 'default'}
        >
          <FormatAlignRightIcon />
        </IconButton>
      </Tooltip>

      <Divider orientation="vertical" flexItem />

      <Tooltip title="Textfarbe">
        <IconButton onClick={onColorPickerOpen}>
          <ColorLensIcon />
        </IconButton>
      </Tooltip>

      <Divider orientation="vertical" flexItem />

      <Tooltip title={isListening ? "Spracherkennung beenden" : "Spracherkennung starten"}>
        <IconButton 
          onClick={toggleRecognition}
          color={isListening ? 'error' : 'default'}
        >
          {isListening ? <MicOffIcon /> : <MicIcon />}
        </IconButton>
      </Tooltip>

      <Tooltip title="Text korrigieren">
        <IconButton onClick={onCorrectText}>
          <AutoFixHighIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Benutzerdefinierte KI-Anweisung">
        <IconButton onClick={onCustomInstruction}>
          <PsychologyIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Hilfe">
        <IconButton onClick={onHelpClick}>
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default EditorToolbar;
