import React from 'react';
import {
  Popover,
  Tabs,
  Tab,
  Grid,
  Box,
  Tooltip
} from '@mui/material';
import { Wheel, Material } from '@uiw/react-color';
import { PRESET_COLORS } from '../../constants/editorConstants';

const ColorPicker = ({
  anchorEl,
  onClose,
  currentColor,
  onColorChange,
  colorPickerTab,
  setColorPickerTab
}) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        sx: { p: 2, width: 300 }
      }}
    >
      <Tabs
        value={colorPickerTab}
        onChange={(_, newValue) => setColorPickerTab(newValue)}
        sx={{ mb: 2 }}
      >
        <Tab label="Voreinstellungen" />
        <Tab label="Farbrad" />
        <Tab label="Erweitert" />
      </Tabs>

      {colorPickerTab === 0 && (
        <Grid container spacing={1}>
          {PRESET_COLORS.map((color) => (
            <Grid item key={color.value}>
              <Tooltip title={color.label}>
                <Box
                  onClick={() => {
                    onColorChange(color.value);
                    onClose();
                  }}
                  sx={{
                    width: 32,
                    height: 32,
                    backgroundColor: color.value,
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                    '&:hover': {
                      opacity: 0.8
                    },
                    ...(color.value === '#FFFFFF' && {
                      border: '1px solid #ccc'
                    })
                  }}
                />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      )}

      {colorPickerTab === 1 && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Wheel
            color={currentColor}
            onChange={(color) => {
              onColorChange(color.hex);
            }}
            style={{ width: 200 }}
          />
        </Box>
      )}

      {colorPickerTab === 2 && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Material
            color={currentColor}
            onChange={(color) => {
              onColorChange(color.hex);
            }}
          />
        </Box>
      )}
    </Popover>
  );
};

export default ColorPicker;
