import React from 'react';
import { 
  Box, 
  Button, 
  Typography,
  TextField 
} from '@mui/material';

const TextEditorSection = ({ 
  projectName, 
  onProjectNameChange, 
  onEditClick, 
  previewText 
}) => {
  return (
    <>
      <TextField
        fullWidth
        label="Projektname"
        value={projectName}
        onChange={(e) => onProjectNameChange(e.target.value)}
        variant="outlined"
        margin="normal"
      />
      <Box sx={{ my: 2 }}>
        <Button
          fullWidth
          variant="outlined"
          onClick={onEditClick}
          sx={{ mb: 1 }}
        >
          Text bearbeiten
        </Button>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
          {previewText.substring(0, 100)}
          {previewText.length > 100 ? '...' : ''}
        </Typography>
      </Box>
    </>
  );
};

export default TextEditorSection;
