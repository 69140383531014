import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Box,
  CircularProgress,
  Paper,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getAvailableModels, correctText, checkCostBeforeRequest } from '../../../api/openRouterService';

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: '#424242',
  color: '#fff',
  '& .MuiSelect-icon': {
    color: '#fff',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.23)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(255, 255, 255, 0.5)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fff',
  },
}));

const StyledMenuItem = styled(MenuItem)({
  '&.MuiMenuItem-root': {
    color: '#fff',
    backgroundColor: '#424242',
    '&:hover': {
      backgroundColor: '#505050',
    },
    '&.Mui-selected': {
      backgroundColor: '#606060',
      '&:hover': {
        backgroundColor: '#707070',
      },
    },
  },
});

const TextPreview = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  backgroundColor: '#1a1a1a',
  color: '#fff',
  maxHeight: '200px',
  overflowY: 'auto',
  '&.corrected': {
    backgroundColor: '#263238',
  }
}));

function TextCorrectionDialog({ open, onClose, text, onApplyCorrection }) {
  const [selectedModel, setSelectedModel] = useState('llama3b');
  const [isLoading, setIsLoading] = useState(false);
  const [correctedText, setCorrectedText] = useState('');
  const [error, setError] = useState('');

  if (!text) {
    return (
      <Dialog 
        open={open} 
        onClose={onClose}
        PaperProps={{
          style: {
            backgroundColor: '#2d2d2d',
            color: '#fff'
          }
        }}
      >
        <DialogContent>
          <Alert severity="warning" sx={{ mt: 2 }}>
            Bitte markieren Sie den Text, den Sie korrigieren möchten.
          </Alert>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button onClick={onClose} color="inherit">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const handleCorrect = async () => {
    try {
      // Prüfung vor der API-Anfrage
      try {
        checkCostBeforeRequest(text, selectedModel);
      } catch (costError) {
        setError(costError.message);
        return;
      }

      setIsLoading(true);
      setError('');
      const result = await correctText(text, selectedModel);
      setCorrectedText(result);
    } catch (error) {
      setError('Fehler bei der Textkorrektur. Bitte versuchen Sie es später erneut.');
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApply = () => {
    onApplyCorrection(correctedText);
    handleClose();
  };

  const handleClose = () => {
    setCorrectedText('');
    setError('');
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: '#2d2d2d',
          color: '#fff'
        }
      }}
    >
      <DialogTitle>Ausgewählten Text korrigieren</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Wählen Sie ein Modell:
          </Typography>
          <FormControl fullWidth>
            <StyledSelect
              value={selectedModel}
              onChange={(e) => setSelectedModel(e.target.value)}
            >
              {getAvailableModels().map(model => (
                <StyledMenuItem key={model.key} value={model.key}>
                  {model.label}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Box>

        <Typography variant="subtitle1">Ausgewählter Text:</Typography>
        <TextPreview>
          {text}
        </TextPreview>

        {correctedText && (
          <>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>Korrigierter Text:</Typography>
            <TextPreview className="corrected">
              {correctedText}
            </TextPreview>
          </>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={handleClose} color="inherit">
          Abbrechen
        </Button>
        {!correctedText ? (
          <Button 
            onClick={handleCorrect} 
            variant="contained" 
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Korrigieren'}
          </Button>
        ) : (
          <Button 
            onClick={handleApply} 
            variant="contained" 
            color="primary"
          >
            Übernehmen
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default TextCorrectionDialog;
