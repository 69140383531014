export const SPEECH_COMMANDS = {
  'punkt': { symbol: '.', capitalize: true },
  'komma': { symbol: ',', capitalize: false },
  'fragezeichen': { symbol: '?', capitalize: true },
  'ausrufezeichen': { symbol: '!', capitalize: true },
  'doppelpunkt': { symbol: ':', capitalize: true },
  'semikolon': { symbol: ';', capitalize: false },
  'bindestrich': { symbol: '-', capitalize: false },
  'gedankenstrich': { symbol: ' — ', capitalize: false },
  'klammer auf': { symbol: '(', capitalize: false },
  'klammer zu': { symbol: ')', capitalize: false },
  'anführungszeichen': { symbol: '"', capitalize: false },
  'anführungszeichen ende': { symbol: '"', capitalize: false },
  'neue zeile': { symbol: '\n', capitalize: true },
  'neuer absatz': { symbol: '\n\n', capitalize: true },
  'tabulator': { symbol: '\t', capitalize: false },
  'einrücken': { symbol: '\t', capitalize: false }
};

export const VOICE_COMMANDS = {
  START: 'start',
  STOP: 'stop',
  PAUSE: 'pause',
  RESUME: 'weiter'
};

export const SPEECH_ERRORS = {
  BROWSER_SUPPORT: 'Spracherkennung wird in diesem Browser nicht unterstützt.',
  MICROPHONE_ACCESS: 'Bitte erlauben Sie den Zugriff auf das Mikrofon.',
  GENERAL_ERROR: 'Ein Fehler ist bei der Spracherkennung aufgetreten.'
};
