import React from 'react';
import { Box } from '@mui/material';
import { convertFromRaw } from 'draft-js';

const TextDisplay = ({
  textRef,
  containerRef,
  scrollPosition,
  isFlippedX,
  isFlippedY,
  fontSize,
  textColor,
  text,
  formattedText,
  countdown,
  containerWidth
}) => {
  const getDisplayText = () => {
    if (formattedText) {
      try {
        const contentState = convertFromRaw(JSON.parse(formattedText));
        const blocks = contentState.getBlocksAsArray();
        return blocks.map((block, i) => {
          const text = block.getText();
          const blockType = block.getType();
          const blockData = block.getData();
          const textAlign = blockData.get('textAlign') || 'left';
          
          const characterList = block.getCharacterList();
          const styles = new Set();
          characterList.forEach(char => {
            char.getStyle().forEach(style => styles.add(style));
          });

          let style = {
            textAlign,
            fontWeight: styles.has('BOLD') ? 'bold' : 'normal',
            fontStyle: styles.has('ITALIC') ? 'italic' : 'normal',
          };

          const colorStyle = Array.from(styles).find(s => s.startsWith('color-'));
          if (colorStyle) {
            style.color = colorStyle.replace('color-', '');
          }

          if (blockType.startsWith('header-')) {
            switch (blockType) {
              case 'header-one':
                style.fontSize = `${fontSize * 2}px`;
                style.fontWeight = 'bold';
                style.marginBottom = '0.67em';
                break;
              case 'header-two':
                style.fontSize = `${fontSize * 1.5}px`;
                style.fontWeight = 'bold';
                style.marginBottom = '0.83em';
                break;
              case 'header-three':
                style.fontSize = `${fontSize * 1.17}px`;
                style.fontWeight = 'bold';
                style.marginBottom = '1em';
                break;
              default:
                break;
            }
          }

          let content = text;
          if (blockType === 'unordered-list-item') {
            style.marginLeft = '1.5em';
            style.listStyleType = 'disc';
            style.display = 'list-item';
          } else if (blockType === 'ordered-list-item') {
            style.marginLeft = '1.5em';
            style.listStyleType = 'decimal';
            style.display = 'list-item';
          }

          return (
            <div key={i} style={style}>
              {content || '\u00A0'}
            </div>
          );
        });
      } catch (e) {
        console.error('Error rendering formatted text:', e);
        return text.split('\n').map((line, i) => (
          <div key={i}>
            {line || '\u00A0'}
          </div>
        ));
      }
    }
    return text.split('\n').map((line, i) => (
      <div key={i}>
        {line || '\u00A0'}
      </div>
    ));
  };

  return (
    <Box ref={containerRef} sx={{ flexGrow: 1, overflow: 'hidden', position: 'relative' }}>
      <Box
        ref={textRef}
        sx={{
          position: 'absolute',
          left: '50%',
          transform: `translate3d(-50%, ${scrollPosition}px, 0) ${isFlippedX ? 'scaleX(-1)' : ''} ${isFlippedY ? 'scaleY(-1)' : ''}`,
          padding: { xs: 3, sm: 4 },
          fontSize: `${fontSize}px`,
          lineHeight: 1.5,
          color: textColor,
          maxWidth: `${containerWidth}%`,
          width: '100%',
          margin: '0 auto',
          boxSizing: 'border-box',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
          willChange: 'transform',
          transition: 'transform 16.67ms linear',
          WebkitFontSmoothing: 'antialiased',
          backfaceVisibility: 'hidden'
        }}
      >
        {getDisplayText()}
      </Box>
      {countdown > 0 && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: textColor,
            fontSize: '10rem',
          }}
        >
          {countdown}
        </Box>
      )}
    </Box>
  );
};

export default TextDisplay;
