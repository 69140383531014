import React, { useState, useEffect } from 'react';
import styles from './LoginForm.module.css';
import auth from './auth';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { LegalDialog } from './menu/dialogs';
import { IMPRESSUM_HTML, DATENSCHUTZ_HTML } from '../content/legal';

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(true);
    const [legalDialogOpen, setLegalDialogOpen] = useState(false);
    const [legalContent, setLegalContent] = useState({
        title: '',
        content: ''
    });

    const handleImpressumClick = () => {
        setLegalContent({
            title: 'Impressum',
            content: IMPRESSUM_HTML
        });
        setLegalDialogOpen(true);
    };

    const handleDatenschutzClick = () => {
        setLegalContent({
            title: 'Datenschutzerklärung',
            content: DATENSCHUTZ_HTML
        });
        setLegalDialogOpen(true);
    };

    useEffect(() => {
        const checkAuth = async () => {
            try {
                if (await auth.checkAccess()) {
                    window.location.href = auth.getBaseUrl();
                }
            } catch (error) {
                console.error('Auth check failed:', error);
            }
        };

        checkAuth();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            await auth.login(username, password);
            window.location.href = auth.getBaseUrl();
        } catch (error) {
            setError(error.message || 'Login fehlgeschlagen');
        } finally {
            setIsLoading(false);
        }
    };

    const toggleTheme = () => {
        setIsDarkMode(!isDarkMode);
    };

    return (
        <div className={`${styles.pageContainer} ${isDarkMode ? styles.dark : ''}`}>
            <button 
                className={`${styles.themeToggle} ${isDarkMode ? styles.dark : styles.light}`}
                onClick={toggleTheme}
            >
                {isDarkMode ? (
                    <>
                        <LightModeIcon /> hell
                    </>
                ) : (
                    <>
                        <DarkModeIcon /> dunkel
                    </>
                )}
            </button>
            
            <main className={styles.mainContent}>
                <div className={`${styles.loginForm} ${isDarkMode ? styles.dark : ''}`}>
                    <h1>Teleprompter Login</h1>
                    
                    <form onSubmit={handleSubmit}>
                        <div className={styles.formGroup}>
                            <label htmlFor="username">E-Mail:</label>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        
                        <div className={styles.formGroup}>
                            <label htmlFor="password">Passwort:</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        
                        <button 
                            type="submit" 
                            disabled={isLoading}
                            className={styles.submitButton}
                        >
                            {isLoading ? 'Anmeldung läuft...' : 'Anmelden'}
                        </button>
                        
                        {error && (
                            <div className={styles.error}>
                                {error}
                            </div>
                        )}
                    </form>
                </div>
            </main>

            <footer className={`${styles.footer} ${isDarkMode ? styles.dark : ''}`}>
                <div className={styles.legalLinks}>
                    <button onClick={handleImpressumClick}>Impressum</button>
                    <span className={styles.divider}>|</span>
                    <button onClick={handleDatenschutzClick}>Datenschutzerklärung</button>
                </div>
            </footer>

            <LegalDialog
                open={legalDialogOpen}
                onClose={() => setLegalDialogOpen(false)}
                title={legalContent.title}
                content={legalContent.content}
                isDarkMode={isDarkMode}
            />
        </div>
    );
};

export default LoginForm;
