import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  Typography, 
  Box, 
  List, 
  ListItem, 
  ListItemText, 
  IconButton,
  Button,
  Link,
  Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import MicIcon from '@mui/icons-material/Mic';

const REMOTE_APP_URL = 'https://teleprompter.videngo.com?remote=true';

function HelpDialog({ open, onClose }) {
  const handleOpenRemoteApp = () => {
    window.open(REMOTE_APP_URL, '_blank');
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          margin: { xs: '16px', sm: '32px' },
          width: 'calc(100% - 32px)',
          maxHeight: 'calc(100% - 32px)',
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          m: 0, 
          p: 2, 
          pr: 6,
          fontSize: { xs: '1.25rem', sm: '1.5rem' }
        }}
      >
        Hilfe
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: { xs: 2, sm: 3 } }}>
        <Divider sx={{ my: 3 }} />

        <Box mb={4}>
          <Typography 
            variant="h6" 
            gutterBottom 
            color="primary"
            sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem' } }}
          >
            Remote Control App
          </Typography>
          <Typography paragraph>
            Mit der Remote Control App können Sie den Teleprompter bequem von einem Smartphone oder Tablet aus steuern. 
            Die App ermöglicht folgende Funktionen:
          </Typography>
          <List dense sx={{ pl: { xs: 0, sm: 2 } }}>
            <ListItem>
              <ListItemText 
                primary="Start/Stop/Pause"
                secondary="Steuern Sie die Wiedergabe des Textes"
                primaryTypographyProps={{ 
                  sx: { fontWeight: 500 } 
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Geschwindigkeitsanpassung"
                secondary="Erhöhen oder verringern Sie die Scrollgeschwindigkeit"
                primaryTypographyProps={{ 
                  sx: { fontWeight: 500 } 
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Position anpassen"
                secondary="Verschieben Sie den Text nach oben oder unten"
                primaryTypographyProps={{ 
                  sx: { fontWeight: 500 } 
                }}
              />
            </ListItem>
          </List>
          
          <Box mt={2} mb={3}>
            <Typography variant="subtitle1" gutterBottom>
              Remote App öffnen:
            </Typography>
            <Box 
              sx={{
                display: 'flex', 
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'flex-start', sm: 'center' },
                gap: 2
              }}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<SmartphoneIcon />}
                onClick={handleOpenRemoteApp}
                sx={{
                  width: { xs: '100%', sm: 'auto' },
                  minHeight: { xs: '48px', sm: '36px' },
                  fontSize: { xs: '1rem', sm: 'inherit' },
                  textTransform: 'none'
                }}
              >
                Remote App starten
              </Button>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{
                  display: 'block',
                  width: { xs: '100%', sm: 'auto' },
                  wordBreak: 'break-all',
                }}
              >
                oder Link kopieren: {' '}
                <Link 
                  href={REMOTE_APP_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ 
                    display: 'inline-flex', 
                    alignItems: 'center',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {REMOTE_APP_URL} <OpenInNewIcon sx={{ ml: 0.5, fontSize: '1rem' }} />
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography 
            variant="h6" 
            gutterBottom 
            color="primary"
            sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem' } }}
          >
            Sprachsteuerung
          </Typography>
          <Typography paragraph>
            Der Teleprompter bietet zwei verschiedene Sprachsteuerungsmöglichkeiten:
          </Typography>

          <Typography 
            variant="subtitle1" 
            gutterBottom 
            color="primary" 
            sx={{ mt: 2, fontSize: { xs: '1rem', sm: '1.1rem' } }}
          >
            1. Spracherkennung im Editor
          </Typography>
          <Typography paragraph>
            Im Texteditor können Sie Ihren Text per Sprache diktieren. Aktivieren Sie die Spracherkennung über das Mikrofon-Symbol im Editor.
          </Typography>

          <Typography 
            variant="subtitle1" 
            gutterBottom 
            color="primary" 
            sx={{ mt: 2, fontSize: { xs: '1rem', sm: '1.1rem' } }}
          >
            2. Sprachsteuerung während des Scrollens
          </Typography>
          <Typography paragraph>
            Während der Text scrollt, können Sie die Wiedergabe per Sprachbefehl steuern. Diese Funktion kann in den App-Einstellungen aktiviert werden.
          </Typography>
          <List dense sx={{ pl: { xs: 0, sm: 2 } }}>
            <ListItem>
              <ListItemText 
                primary="Verfügbare Befehle"
                secondary={`
                  "[Initialwort] start" - Startet das Scrollen
                  "[Initialwort] stop" - Stoppt das Scrollen
                  "[Initialwort] pause" - Pausiert das Scrollen
                  "[Initialwort] weiter" - Setzt das Scrollen fort
                `}
                primaryTypographyProps={{ 
                  sx: { fontWeight: 500 } 
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Initialwort anpassen"
                secondary="Das Initialwort (Standard: 'achtung') kann in den App-Einstellungen geändert werden."
                primaryTypographyProps={{ 
                  sx: { fontWeight: 500 } 
                }}
              />
            </ListItem>
          </List>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Typography 
          variant="h6" 
          gutterBottom 
          color="primary"
          sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem' } }}
        >
          Grundlegende Funktionen
        </Typography>
        <List dense sx={{ pl: { xs: 0, sm: 2 } }}>
          <ListItem>
            <ListItemText 
              primary="Texteingabe"
              secondary="Geben Sie Ihren Text direkt ein oder laden Sie gespeicherte Texte. Der Editor unterstützt Formatierungen wie Fett, Kursiv und Unterstreichungen."
              primaryTypographyProps={{ 
                sx: { fontWeight: 500 } 
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Textanpassung"
              secondary="Passen Sie Schriftgröße, Scrollgeschwindigkeit und Textbreite an Ihre Bedürfnisse an. Die Einstellungen können gesperrt werden, um versehentliche Änderungen zu vermeiden."
              primaryTypographyProps={{ 
                sx: { fontWeight: 500 } 
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Spiegelung"
              secondary="Der Text kann horizontal und vertikal gespiegelt werden, ideal für verschiedene Teleprompter-Setups."
              primaryTypographyProps={{ 
                sx: { fontWeight: 500 } 
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Projektverwaltung"
              secondary="Speichern und laden Sie verschiedene Projekte. Alle Einstellungen werden mit dem Projekt gespeichert."
              primaryTypographyProps={{ 
                sx: { fontWeight: 500 } 
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Vollbildmodus"
              secondary="Nutzen Sie den Vollbildmodus für eine optimale Darstellung ohne störende Elemente."
              primaryTypographyProps={{ 
                sx: { fontWeight: 500 } 
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Feinsteuerung"
              secondary="Während der Wiedergabe können Sie die Position des Textes und die Geschwindigkeit jederzeit anpassen."
              primaryTypographyProps={{ 
                sx: { fontWeight: 500 } 
              }}
            />
          </ListItem>
        </List>

        <Divider sx={{ my: 3 }} />

        <Typography 
          variant="h6" 
          gutterBottom 
          color="primary" 
          sx={{ mt: 3, fontSize: { xs: '1.1rem', sm: '1.25rem' } }}
        >
          Tipps zur Verwendung
        </Typography>
        <List dense sx={{ pl: { xs: 0, sm: 2 } }}>
          <ListItem>
            <ListItemText 
              primary="Vorbereitung"
              secondary="Testen Sie die Geschwindigkeit und Schriftgröße vor der eigentlichen Nutzung."
              primaryTypographyProps={{ 
                sx: { fontWeight: 500 } 
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Sprachsteuerung"
              secondary="Nutzen Sie die Sprachsteuerung für eine handfreie Bedienung während der Präsentation."
              primaryTypographyProps={{ 
                sx: { fontWeight: 500 } 
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Remote Control"
              secondary="Nutzen Sie die Remote App für eine bessere Kontrolle während der Präsentation."
              primaryTypographyProps={{ 
                sx: { fontWeight: 500 } 
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Speichern"
              secondary="Speichern Sie Ihre Projekte regelmäßig, um keine Änderungen zu verlieren."
              primaryTypographyProps={{ 
                sx: { fontWeight: 500 } 
              }}
            />
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
}

export default HelpDialog;