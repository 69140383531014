export const IMPRESSUM_HTML = `
<p>Ralf Pareigis<br />
Am M&uuml;hlenkamp 28<br />
47625 Kevelaer</p>

<h2>Kontakt</h2>
<p>Telefon: 0173-7057416<br />
Telefax: 0221-790761008<br />
E-Mail: info@vertrieb-im-netz.de</p>

<h2>Umsatzsteuer-ID</h2>
<p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
DE280256727</p>

<h2>EU-Streitschlichtung</h2>
<p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
<p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
`;
